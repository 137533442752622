import React from "react";
import LandBoxContainer from "../LandBoxContainer";

import Banner from "../../Components/Banner";
import { TopSellerContainer } from "../../Components/Container";
import { TopSellerCard, TopSellerCard2, TopSellerCard3, TopSellerCard4, TopSellerCard5, TopSellerCard6 } from "../../Components/Cards";

export default function Home({ activeUser, showWaxModal, onUserLogout }) {
  return (
    <LandBoxContainer
      activeUser={activeUser}
      showWaxModal={showWaxModal}
      onUserLogout={onUserLogout}
    >
      <Banner>
        <TopSellerContainer>
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title mb-40">
                <h2 className="title">
                  Live Modes{" "}
                  <img src="assets/img/icons/title_icon01.png" alt="" />
                </h2>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <TopSellerCard title={`P2E Mining`}>
                Play on a <span>Browser</span>. Requirements a gamer & computer NFT.
              </TopSellerCard>
            </div>
	  	  <div className="col-xl-3 col-lg-4 col-sm-6">
              <TopSellerCard3 title={`Open World`}>
                Play on a <span>Windows Computer</span>. Requirements game pass & a character.
              </TopSellerCard3>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <TopSellerCard2 title={`100 Mode`}>
                Play on a <span>Windows Computer</span>. Requirements a game pass, gamer or VIP.
              </TopSellerCard2>
            </div>
	  
	  	  <div className="col-xl-3 col-lg-4 col-sm-6">
              <TopSellerCard4 title={`Creative Mode`}>
                Play on a <span>Windows Computer</span>. Requirements game pass, builder or VIP.
              </TopSellerCard4>
            </div>
	  
	    	  <div className="col-xl-3 col-lg-4 col-sm-6">
              <TopSellerCard5 title={`Monster Fight: Reborn`}>
                Play on any <span>Computer</span>. Requirements 3 Monsters.
              </TopSellerCard5>
            </div> 
	  
	    <div className="col-xl-3 col-lg-4 col-sm-6">
              <TopSellerCard6 title={`King of Clans`}>
                Play on any <span>Computer</span>. Use King of Clans heroes from LandBox to play.
              </TopSellerCard6>
            </div> 

            
          </div>
        </TopSellerContainer>
      </Banner>
    </LandBoxContainer>
  );
}
