import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/";
import SideBar from "../Components/IndexOne/SideBar";
import Breadcrumb from "../Components/InnerPages/marketsingle/Breadcrumb";
import SingleMarket from "../Components/InnerPages/marketsingle/SingleMarket";

const MarketSingle = () => {
  return (
    <>
      <SideBar />
      <div className="main-content">
        <Header />

        <main>
          <Breadcrumb />
          <SingleMarket />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default MarketSingle;
