import React from "react";

export default function Banner({ buttonText = "JOIN OUR DISCORD", children }) {
  return (
    <div className="banner-bg">
      <div className="banner-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8">
              <div className="banner-content">
                <h2 className="title">
                  LANDBOX THE<br /> <span>OPEN</span> WORLD FOR ALL
                </h2>
                <p>
                 Embark on an unparalleled adventure in LandBox's limitless open world. Engage in diverse game modes and thrilling challenges like King of Clans and Monster Fight Reborn. For collections eager to establish a booth in our landscape, connect with us on Discord. Step into LandBox – a world of infinite possibilities.
                </p>
                <a href="https://discord.gg/AYR5fhB2z9" target="_new" className="banner-btn">
                  {buttonText} <i className="fi-sr-arrow-right" />
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-4">
              <div className="banner-image">
                <img src="../assets/img/landbox-features.jpg" alt="LandBox Game" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {children}
    </div>
  );
}
