import { Link } from "react-router-dom";
import RoutePaths from "../../routes/routePath";

export function Breadcrumb({ page, link }) {
  const { home } = RoutePaths;

  return (
    <div className="breadcrumb-area market-single-breadcrumb-area mt-180">
      <div className="breadcrumb-bg" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="market-single-breadcrumb">
              <div className="home-back-btn">
                <Link to={home}>go back home</Link>
              </div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={home}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {page}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
