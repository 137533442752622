import React from 'react'

const Footer = () => {
  return (
    <footer>		  
    <div className="footer-top-wrap">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-xl-3 col-lg-4 col-md-5 col-sm-9">
            <div className="footer-widget">
              <div className="footer-logo mb-25">
                <a href="/index"><img src="assets/img/landbox-logo-website.png" alt="landbox" /></a>
              </div>
              <p>LandBox is a sandbox type blockchain game. Take your creative mind and start to explore around the world and earn.</p>
              <ul className="footer-social">
                <li><a href="https://twitter.com/landboxio"><i className="fab fa-twitter" /></a></li>
                <li><a href="https://discord.gg/AYR5fhB2z9"><i className="fab fa-discord" /></a></li>
                <li><a href="https://t.me/landboxio"><i className="fab fa-telegram" /></a></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6">
            <div className="footer-widget">
              <h4 className="fw-title">Useful Links</h4>
              <ul className="fw-links">
                <li><a href="https://neftyblocks.com/c/landboxgames/">NeftyBlocks</a></li>
                <li><a href="https://wax.atomichub.io/explorer/collection/landboxgames">AtomicHub</a></li>
                <li><a href="https://landboxio.gitbook.io/landbox-game-guide/">Guide</a></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6">
            <div className="footer-widget">
              <h4 className="fw-title">Download</h4>
              <ul className="fw-links">
                <li><a href="https://launcher.elixir.app/browse/landbox">Download on Elixir</a></li>
				  <li><a href="https://landboxgame.com/play">Play on a Browser</a></li>
              </ul>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="footer-widget">
          
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="copyright-wrap">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="copyright-text">
              <p>All rights reserved © 2023 by Publisher & Developer: <a href="https://pwnedstudio.com">PWNED Studio, LLC.</a> Powered by <a href="https://gitgame.io">GitGame.io</a></p>
            </div>
          </div>
          <div className="col-md-6">
            <ul className="copyright-link-list">
              <li><a href="mailto:landboxio@gmail.com">Contact Us</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default Footer