import miningPageConstants from "./constant";

export function getMiningBonus(bits) {
  const { miningBonus } = miningPageConstants;

  let bonus = 0;
  miningBonus.forEach((b) => {
    if (bits > b.bitValue) bonus = b.bonus;
  });

  return bonus;
}

export function getCardRate(config, templates, assets, gamer) {
  const defaultLevel = 1;
  let multiplier = 0;
  let pcRate = 0;

  if (config) multiplier = parseFloat(config.multiplier);

  const gamerDetail = assets.find((a) => a.asset_id === gamer.asset_id);
  const gamerTemplate = templates.find(
    (t) => t.template_id === parseInt(gamerDetail.template?.template_id)
  );
  const pcLevel = gamer?.pcids.map((p) => {
    const detail = assets.find((a) => a.asset_id === p);
    const template = templates.find(
      (t) => t.template_id === parseInt(detail.template?.template_id)
    );
    const level = detail.mutable_data?.Level ? detail.mutable_data?.Level : 1;
    const rate = parseInt(template.boost) * parseInt(level);

    pcRate += rate;
    return rate;
  });

  const gamerLevel =
    gamerDetail && gamerDetail.mutable_data?.Level
      ? parseInt(gamerDetail.mutable_data?.Level)
      : defaultLevel;

  const gamerRate = parseInt(gamerTemplate?.boost) * gamerLevel;

  return multiplier * gamerRate * pcRate;
}

export function computeMiningPower(
  assets,
  templates,
  configuration,
  gamers,
  pcs
) {
  const defaultLevel = 1;
  let gamerLevels = [];
  let miningPower = 0;
  let multiplier = defaultLevel;

  if (configuration) {
    const config = configuration.find((c) => c.multiplier);
    multiplier = parseFloat(config?.multiplier);
  }

  gamers.forEach((g) => {
    let pcLevel = 0;
    const gamerLevel = g?.Level ? g.level : defaultLevel;
    const assetDetails = assets.find((a) => a.asset_id === g.asset_id);
    const templateDetails = !assetDetails
      ? null
      : templates.find(
          (t) => t.template_id === parseInt(assetDetails.template?.template_id)
        );
    const GamerRarityBoost = templateDetails
      ? parseInt(templateDetails?.boost)
      : defaultLevel;

    for (let pcAssetId of g.pcids) {
      const pcAssetDetail = assets.find((p) => p.asset_id === pcAssetId);
      const pcTemplateData = templates.find(
        (t) => t.template_id === parseInt(pcAssetDetail?.template?.template_id)
      );

      const pcRarityBoost = pcTemplateData
        ? parseInt(pcTemplateData?.boost)
        : defaultLevel;

      if (pcAssetDetail?.mutable_data?.Level && pcTemplateData) {
        pcLevel +=
          parseInt(pcRarityBoost) *
          parseInt(pcAssetDetail?.mutable_data?.Level);
      }
    }

    if (g.pcids.length > 0)
      gamerLevels.push(GamerRarityBoost * gamerLevel * pcLevel);
  });

  for (let level of gamerLevels) {
    miningPower += multiplier * level;
  }

  return miningPower;
}
