import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import RoutePaths from "./routes/routePath";
import $ from "jquery";

import Activity from "./pages/Activity";
import AuthorProfile from "./pages/AuthorProfile";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import Category from "./pages/Category";
import Collections from "./pages/Collections";
import CreateItem from "./pages/CreateItem";
import Creators from "./pages/Creators";
import DropsPage from "./pages/DropsPage";
import Explore from "./pages/Explore";
import FarmingPage from "./pages/FarmingPage";
import Home from "./pages/HomePage/";
import LoginRegister from "./pages/LoginRegister";
import MiningPage from "./pages/MiningPage";
import MarketSingle from "./pages/MarketSingle";
import ModulesPage from "./pages/ModulesPage";
import NftLiveBidding from "./pages/NftLiveBidding";
import NeftyPage from "./pages/NeftyPage";
import Ranking from "./pages/Ranking";

export default function App({ ual }) {
  useEffect(() => {
    $(".menu-trigger").on("click", function () {
      $(".offcanvas-wrapper,.offcanvas-overly").addClass("active");
      return false;
    });
    $(".menu-close,.offcanvas-overly").on("click", function () {
      $(".offcanvas-wrapper,.offcanvas-overly").removeClass("active");
    });
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route
          path={RoutePaths.home}
          element={
            <Home
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
              showWaxModal={ual.showModal}
              onUserLogout={ual.logout}
            />
          }
        />

        <Route
          path={RoutePaths.farming}
          element={
            <FarmingPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
              showWaxModal={ual.showModal}
              onUserLogout={ual.logout}
            />
          }
        />

        <Route
          path={RoutePaths.mining}
          element={
            <MiningPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
              showWaxModal={ual.showModal}
              onUserLogout={ual.logout}
            />
          }
        />

        <Route
          path={RoutePaths.marketplace}
          element={
            <NeftyPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
              showWaxModal={ual.showModal}
              onUserLogout={ual.logout}
            />
          }
        />

        <Route
          path={RoutePaths.drops}
          element={
            <DropsPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
              showWaxModal={ual.showModal}
              onUserLogout={ual.logout}
            />
          }
        />

        <Route
          path={RoutePaths.testingModule}
          element={
            <ModulesPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
              showWaxModal={ual.showModal}
              onUserLogout={ual.logout}
            />
          }
        />

        <Route path="/nft-marketplace" element={<Explore />} />
        <Route path="/collections" element={<Collections />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/activity" element={<Activity />} />
        <Route path="/ranking" element={<Ranking />} />
        <Route path="/login-register" element={<LoginRegister />} />
        <Route path="/author-profile" element={<AuthorProfile />} />
        <Route path="/create-item" element={<CreateItem />} />
        <Route path="/category" element={<Category />} />
        <Route path="/creators" element={<Creators />} />
        <Route path="/mining" element={<MarketSingle />} />
        <Route path="/nft-live-bidding" element={<NftLiveBidding />} />
      </Routes>
    </div>
  );
}
