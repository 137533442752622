const waxUrl = {
  contract: "landminer.gm",
  landboxContract: "landbox.gm",
  collectionName: "landboxgames",
  assetImageUrl: "https://wax.atomichub.io/explorer/asset",
  IpfsImageUrl: "https://atomichub-ipfs.com/ipfs",
  rpcEndpoint: "https://wax-api.gnocity.io",
  baseAtomicAssets: "https://wax-aa.gnocity.io",
  waxBlocks: "https://wax.bloks.io",
  graymassTableRows: "https://wax.greymass.com",
};

export default waxUrl;
