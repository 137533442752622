const RoutePaths = {
  home: "/",
  drops: "/drops",
  farming: "/farming",
  marketplace: "/marketplace",
  mining: "/mining",
  testingModule: "/module",
};

export default RoutePaths;
