import React, { useState, useEffect } from "react";
import classNames from "classnames";

import "./index.scss";

export function Modal({
  title = "",
  className = "",
  wrapperClassName = "",
  isOpen = false,
  messagePositive = true,
  modalMessage = "",
  popupMessage = "",
  modalTransparent = true,
  onMessageClose,
  onModalClose,
  children,
}) {
  const [modalOpen, setModalOpen] = useState(isOpen);
  const [message, setMessage] = useState(popupMessage);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setMessage(popupMessage);
  }, [popupMessage]);

  const backgroundClass = classNames({
    "popup-modal__background--transparent": modalTransparent,
  });

  const messageClass = classNames({
    "popup-modal__message--positive": messagePositive,
    "popup-modal__message--negative": !messagePositive,
  });

  const popBtnClass = classNames({
    "popup-modal__pop-btn--green": messagePositive,
    "popup-modal__pop-btn--red": !messagePositive,
  });

  const closeButtonClass = classNames({
    "popup-modal__header-btn--violet": title,
  });

  return !modalOpen ? null : (
    <div className={`popup-modal popup-modal__background ${backgroundClass}`}>
      <div className={`popup-modal__wrapper ${wrapperClassName}`}>
        <button
          className={`popup-modal__header-btn ${closeButtonClass}`}
          onClick={() => {
            setModalOpen(false);
            if (onModalClose) onModalClose();
          }}
        >
          ✖
        </button>
        {!title ? null : <h4 className="popup-modal__title">{title}</h4>}
        <div className={`popup-modal__content ${className}`}>
          {!message ? null : (
            <div className={`popup-modal__pop-container ${messageClass}`}>
              <div className={`popup-modal__pop-message`}>
                {message}
                <div>
                  <button
                    className={`popup-modal__pop-btn ${popBtnClass}`}
                    onClick={() => {
                      setMessage("");
                      if (onMessageClose) onMessageClose();
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          )}
          {!modalMessage ? null : (
            <div className={`popup-modal__message ${messageClass}`}>
              *{modalMessage}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
