import React, { useEffect, useState } from "react";
import classNames from "classnames";

import LandBoxContainer from "../LandBoxContainer";
import { Breadcrumb } from "../../Components/Breadcrumb";
import { Modal } from "../../Components/Modal";
import { ProductList } from "../../Components/List";
import {
  AuthorProfileCard,
  CollectionItemCard,
  CollectionItemCardBtnDetails,
  CollectionItemCardPC,
} from "../../Components/Cards";

import { getUserBalance } from "../../Services/userService";
import { getAssetCollectionByAccountName } from "../../Services/AssetsService";
import { getConfigurations } from "../../Services/GamerService";
import transactionSigner from "../../Services/TransactionSigner";

import { getToFixedNumber, isDecimal, removeSuffx } from "../../utils/helpers";
import { computeMiningPower, getCardRate, getMiningBonus } from "./helper";
import miningPageConstants from "./constant";
import waxUrl from "../../constants/waxUrl";

import "./index.scss";

export default function MiningPage({
  userProperties,
  activeUser,
  showWaxModal,
  onUserLogout,
}) {
  const { contract, landboxContract, collectionName, IpfsImageUrl } = waxUrl;

  const [gamer, setGamer] = useState("");
  const [stakedGamer, setStakedGamer] = useState("");
  const [gamerMessage, setGamerMessage] = useState("");
  const [gamerPositive, setGamerPositive] = useState(true);

  const [cardModal, setCardModal] = useState(false);
  const [cardModalMessage, setCardModalMessage] = useState("");
  const [cardPositive, setCardPositive] = useState(true);

  const [exchangeModal, setExchangeModal] = useState(false);
  const [exchangeModalMessage, setExchangeModalMessage] = useState("");
  const [exchangePositive, setExchangePositive] = useState(true);

  const [popupMessage, setPopupMessage] = useState("");
  const [popupSuccess, setPopupSuccess] = useState(true);

  const [assets, setAssets] = useState([]);
  const [templates, setTemplates] = useState([]);

  const [balances, setBalances] = useState([]);
  const [lanbxBalances, setLanbxBalances] = useState([]);
  const [config, setConfig] = useState(null);

  const [stakedGamers, setStakedGamers] = useState([]);
  const [stakedPCs, setStakedPCs] = useState([]);

  const [miningPower, setMiningPower] = useState(0);
  const [exchangeBits, setExchangeBits] = useState(false);
  const [exchangeValue, setExchangeValue] = useState(0);

  const _serviceInit = async (collectionName, accountName) => {
    try {
      if (accountName) {
        const assets = await getAssetCollectionByAccountName(
          collectionName,
          accountName
        );

        const templates = await getConfigurations("bconfigs");
        const lanbxBalance = await getUserBalance("accounts", accountName);
        const balances = await getConfigurations(
          "balances",
          accountName,
          accountName
        );
        const configs = await getConfigurations("configs");

        if (lanbxBalance.success) {
          const landbxBalance = lanbxBalance.responseData?.rows;
          setLanbxBalances(landbxBalance.map((b) => b?.balance));
        }

        if (balances.success) {
          const bitsBalance = balances.responseData?.rows;
          setBalances(bitsBalance.map((b) => b?.balance));
        }

        if (assets.success) {
          setAssets(assets.responseData?.data);
        }

        if (templates.success) {
          setTemplates(templates.responseData?.rows);
        }

        if (configs.success) {
          setConfig(configs.responseData?.rows);
        }
      }
    } catch (error) {
      const _isErrorEmpty =
        error instanceof Object && Object.keys(error).length === 0;

      if (_isErrorEmpty) {
        console.log("🎶🎶🎶", error);
        return;
      }
      setPopupMessage(error);
      setPopupSuccess(false);
    }
  };
		  
	function getNextClaimTime(activeUser, stakedGamers) {
	  console.log('getNextClaimTime called', { activeUser, stakedGamers });

  const gamerData = stakedGamers.find(gamer => gamer.owner === activeUser);

  if (gamerData) {
    const lastClaimEpoch = gamerData.last_claim; // Assuming last_claim is in epoch time (seconds)
    const lastClaimDate = new Date(lastClaimEpoch * 1000); // Convert to milliseconds
    const nextClaimDate = new Date(lastClaimDate.getTime() + 3600000); // Add 1 hour (3600000 milliseconds)

    return nextClaimDate;
  } else {
    console.error('No gamer data found for the active user.');
    return null;
  }
}

// Usage example
const nextClaimDate = getNextClaimTime(activeUser, stakedGamers);
if (nextClaimDate) {
  const currentTime = new Date();
  if (currentTime >= nextClaimDate) {
    //console.log('You can claim now. Do not upgrade or assign anything new. If you do upgrade or assign you will have to wait another hour before you can claim.');
  } else {
   // console.log(`You can claim again at ${nextClaimDate.toLocaleTimeString()}.`);
  }
}			  
					  
const [nextClaimTime, setNextClaimTime] = useState(null);

useEffect(() => {
  const calculatedNextClaimTime = getNextClaimTime(activeUser, stakedGamers);
  if (calculatedNextClaimTime) {
    setNextClaimTime(calculatedNextClaimTime.toLocaleTimeString());
  }
}, [activeUser, stakedGamers]);

const [nextClaimMessage, setNextClaimMessage] = useState('');

useEffect(() => {
  const updateNextClaimMessage = () => {
    const currentTime = new Date();
    const nextTime = getNextClaimTime(activeUser, stakedGamers);
    if (nextTime) {
      if (currentTime >= nextTime) {
        setNextClaimMessage('You can claim now. Do not upgrade or assign anything new. If you do upgrade or assign you will have to wait another hour before you can claim.');
      } else {
        setNextClaimMessage(`You can claim all rewards again at ${nextTime.toLocaleTimeString()}`);
      }
    } else {
      setNextClaimMessage('No claim data available');
    }
  };

  // Call the function immediately and set an interval
  updateNextClaimMessage();
  const interval = setInterval(updateNextClaimMessage, 60000); // Update every minute

  // Clear the interval on component unmount
  return () => clearInterval(interval);
}, [activeUser, stakedGamers]);

  const _gamersInit = async (accountName) => {
    try {
      const gamers = await getConfigurations("gamers", accountName);
      const pcs = await getConfigurations("pcs", accountName);

      if (gamers.success) {
        setStakedGamers(gamers.responseData?.rows);
      }

      if (pcs.success) {
        setStakedPCs(pcs.responseData?.rows);
      }
    } catch (error) {
      const _isErrorEmpty =
        error instanceof Object && Object.keys(error).length === 0;

      if (_isErrorEmpty) {
        console.log("🎶🎶🎶", error);
        return;
      }

      setPopupMessage(error);
      setPopupSuccess(false);
    }
  };

  const [unclaimedMiningRate, setUnclaimedMiningRate] = useState(0);

 useEffect(() => {
    const calculateUnclaimedMiningRate = () => {
      const miningRatePerHour = getToFixedNumber(
        miningPower + miningPower * (getMiningBonus(parseInt(removeSuffx(balances, /BITS/gi))) * 0.01),
        4
      );

      const stakedGamerData = stakedGamers.find(gamer => gamer.owner === activeUser);
      if (stakedGamerData) {
        const lastClaimEpoch = stakedGamerData.last_claim;
        const hoursSinceLastClaim = (Date.now() / 1000 - lastClaimEpoch) / 3600;
        const unclaimedRate = miningRatePerHour * hoursSinceLastClaim;
        setUnclaimedMiningRate(unclaimedRate.toFixed(4)); // Update state with the calculated rate
      }
    };

    // Initial calculation on component mount
    calculateUnclaimedMiningRate();

    // Set up an interval to update the rate every second
    const intervalId = setInterval(calculateUnclaimedMiningRate, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [activeUser, stakedGamers, miningPower, balances]);


  useEffect(() => {
    const walletTester = "onmg2.wam";
    const walletUser = "tpjb4.wam";
    const accountName = activeUser;
    if (activeUser && collectionName) {
      _serviceInit(collectionName, accountName);
      _gamersInit(accountName);
    }
  }, [collectionName, activeUser]);

  useEffect(() => {
    setMiningPower(
      computeMiningPower(
        assets,
        templates,
        config,
        stakedGamers.filter((g) => g.owner === activeUser),
        stakedPCs,
        activeUser
      )
    );
  }, [assets, templates, config, stakedGamers, stakedPCs, activeUser]);

  return (
    <LandBoxContainer
      activeUser={activeUser}
      showWaxModal={showWaxModal}
      onUserLogout={onUserLogout}
    >
      <Breadcrumb page="Mining" />

      <Modal isOpen={popupMessage} className="mining-page__modal-content">
        {popupSuccess ? (
          <span className="mining-page__text--green mining-page__text--centered">
            {popupMessage}
          </span>
        ) : (
          <span className="mining-page__text--red mining-page__text--centered">
            {popupMessage}
          </span>
        )}
      </Modal>

      <Modal
        isOpen={gamer}
        popupMessage={gamerMessage}
        messagePositive={gamerPositive}
        className="mining-page__modal-content"
        wrapperClassName="mining-page__card-wrapper"
        onModalClose={() => setGamer("")}
      >
        <div className="row justify-content-center">
          <h3>Selected Gamer</h3>
          <div className="mining-page__gamer-image">
            <img
              className="mining-page__selected-image"
              src={`${IpfsImageUrl}/${gamer?.data?.img}`}
              alt="card"
            />
          </div>
          <div className="mining-page__pc-grid row">
            <h3>PCs</h3>
            {assets.length &&
              assets
                .filter((a) => {
                  const _isStaked = stakedPCs.find(
                    (sp) =>
                      sp.asset_id === a.asset_id && sp.owner === activeUser
                  );

                  const templateData = templates.find(
                    (t) => t.template_id === parseInt(a.template?.template_id)
                  );

                  return (
                    !_isStaked &&
                    templateData &&
                    templateData?.type === miningPageConstants.types.pc
                  );
                })
                .map((a, i) => {
                  const _isStaked = stakedPCs.find(
                    (sp) =>
                      sp.asset_id === a.asset_id && sp.owner === activeUser
                  );

                  const cardLevel = a.mutable_data?.Level
                    ? a.mutable_data?.Level
                    : "1";

                  return (
                    <div className="col-lg-3">
                      <CollectionItemCard
                        key={`pc${i}`}
                        secondaryBtn={_isStaked}
                        detail={`Lvl ${cardLevel}`}
                        imagePath={`${IpfsImageUrl}/${a?.data?.img}`}
                        rarity={a?.data?.name}
                        buttonText={"Assign"}
                        onButtonClick={async () => {
                          const response = await _handleActivatePC(
                            userProperties,
                            activeUser,
                            contract,
                            a.asset_id,
                            gamer.asset_id
                          );

                          if (response.success) {
                            const pcLevel = a.mutable_data?.Level
                              ? parseInt(a.mutable_data?.Level)
                              : 1;

                            const gamerDetails = stakedGamers.find(
                              (sg) => sg.asset_id === gamer?.asset_id
                            );

                            const filteredStakedGamers = stakedGamers.filter(
                              (sg) => sg.asset_id !== gamer?.asset_id
                            );

                            const pcData = {
                              asset_id: a.asset_id,
                              g_id: gamer?.asset_id,
                              owner: activeUser,
                              level: pcLevel,
                            };

                            const gamerData = {
                              asset_id: gamer?.asset_id,
                              owner: activeUser,
                              pcids: [...gamerDetails?.pcids, a.asset_id],
                              level: gamerDetails.level,
                            };

                            setStakedPCs([...stakedPCs, pcData]);
                            setStakedGamers([
                              ...filteredStakedGamers,
                              gamerData,
                            ]);

                            setGamerPositive(true);
                            setGamerMessage(`PC Registration Successful`);
                            return;
                          }

                          setGamerPositive(false);
                          setGamerMessage(response?.responseData.message);
                        }}
                      />
                    </div>
                  );
                })}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={cardModal}
        modalTransparent={false}
        popupMessage={cardModalMessage}
        messagePositive={cardPositive}
        className="mining-page__modal-content"
        wrapperClassName="mining-page__card-wrapper"
        title="Staked Gamers"
        onMessageClose={() => {
          setCardModalMessage("");
        }}
        onModalClose={() => {
          setCardModal(false);
          setStakedGamer(null);
          setCardModalMessage("");
        }}
      >
        <div className="row justify-content-center">
          <div className="mining-page__pc-grid row">
            <h3>
              {!stakedGamer ? `Staked Gamers` : `PCS`}{" "}
              {!stakedGamer ? null : (
                <button className="btn" onClick={() => setStakedGamer(null)}>
                  Back
                </button>
              )}
            </h3>

            {stakedGamer &&
              stakedGamer?.pcids.map((p, i) => {
                const pcDetail = assets.find((a) => {
                  return a?.asset_id === p;
                });

                const cardLevel = pcDetail?.mutable_data?.Level
                  ? pcDetail?.mutable_data?.Level
                  : "1";

                return (
                  <div className="col-lg-3">
                    <CollectionItemCard
                      key={`gamer${i}`}
                      detail={`Lvl ${cardLevel}`}
                      imagePath={`${IpfsImageUrl}/${pcDetail?.data?.img}`}
                      rarity={pcDetail?.data?.name}
                      buttonText={"REMOVE"}
                      onButtonClick={async () => {
                        const response = await _handleDeactivatePC(
                          userProperties,
                          activeUser,
                          contract,
                          p
                        );

                        if (response.success) {
                          const filteredGamers = stakedGamers.filter(
                            (sg) => sg.asset_id !== stakedGamer?.asset_id
                          );

                          const filteredGamerPCs = stakedGamer?.pcids.filter(
                            (pc) => pc !== p
                          );

                          const filteredPCs = stakedPCs.filter(
                            (p) => p.asset_id !== p
                          );

                          const gamerData = {
                            asset_id: stakedGamer.asset_id,
                            owner: activeUser,
                            pcids: filteredGamerPCs,
                            level: stakedGamer?.level,
                          };

                          setCardPositive(true);
                          setCardModalMessage("PC Deactivation Successful");

                          setStakedPCs(filteredPCs);
                          setStakedGamer(gamerData);
                          setStakedGamers([...filteredGamers, gamerData]);
                          return;
                        }

                        setCardPositive(false);
                        setCardModalMessage("PC Deactivation Successful");
                      }}
                    />
                  </div>
                );
              })}

            {!stakedGamer &&
              assets.length &&
              assets
                .filter((a) => {
                  const cardDetail = stakedGamers.find(
                    (s) => a.asset_id === s.asset_id
                  );

                  return cardDetail && cardDetail?.pcids.length;
                })
                .map((a, i) => {
                  const _getHealthClaims = (healthClaims) => {
                    if (healthClaims) {
                      const claimsSplitted = healthClaims.split(",");

                      return {
                        claims: parseInt(claimsSplitted[0]),
                        health: parseInt(claimsSplitted[1]),
                      };
                    }

                    return {
                      claims: 0,
                      health: 100,
                    };
                  };

                  const _getRechargeCost = (level, configuration) => {
                    const BITS_COST = miningPageConstants.cost.recharge;
                    const defaultUpgradeLevel = 1;
                    const upgradeLevel = level
                      ? parseInt(level)
                      : defaultUpgradeLevel;

                    const cost = configuration[0].lhcost.find((c) => {
                      return c.key === upgradeLevel;
                    });

                    return !cost ? 0 : `${cost.value * BITS_COST} BITS`;
                  };

                  const _getBitCost = (level, configuration) => {
                    const defaultUpgradeLevel = 2;
                    const upgradeLevel = level
                      ? parseInt(level) + 1
                      : defaultUpgradeLevel;

                    const bitCost = configuration[0].cbitcost.find((cb) => {
                      return cb.key === upgradeLevel;
                    });

                    return !bitCost ? 0 : bitCost.value;
                  };

                  const _getCuboCost = (level, configuration) => {
                    const defaultUpgradeLevel = 2;
                    const upgradeLevel = level
                      ? parseInt(level) + 1
                      : defaultUpgradeLevel;

                    const cuboCost = configuration[0].ccbcost.find((cc) => {
                      return cc.key === upgradeLevel;
                    });

                    return !cuboCost ? 0 : cuboCost.value;
                  };

                  const _getHealthCost = (level, configuration) => {
                    const defaultUpgradeLevel = 1;
                    const upgradeLevel = level
                      ? parseInt(level)
                      : defaultUpgradeLevel;

                    const healthCost = configuration[0].chcost.find((ch) => {
                      return ch.key === upgradeLevel;
                    });

                    return !healthCost ? 0 : healthCost.value;
                  };

                  const overlayed =
                    _getHealthClaims(a.mutable_data?.CBH).health <= 0;

                  const cardLevel = a.mutable_data?.Level
                    ? a.mutable_data?.Level
                    : "1";

                  const cardDetail = stakedGamers.find(
                    (s) => a.asset_id === s.asset_id
                  );

                  const configDetail = config.find((c) => c.multiplier);

                  const cardRate = getCardRate(
                    configDetail,
                    templates,
                    assets,
                    cardDetail
                  );

                  const buttonOverlayClass = classNames({
                    "mining-page__btn--gray": overlayed,
                  });

                  return (
                    <div className="col-lg-3">
                      <CollectionItemCardBtnDetails
                        upgradeBtn
                        button={!overlayed}
                        healBtn={!overlayed}
                        overlay={overlayed}
                        overlayColor="gray"
                        overlayButtonText="Recharge"
                        key={`card${i}`}
                        upgradeDetail={[
                          {
                            title: `Rate`,
                            detail: `${getToFixedNumber(cardRate, 4)} BITS/HR`,
                          },
                          {
                            title: `Claims`,
                            detail: _getHealthClaims(a.mutable_data?.CBH)
                              .claims,
                          },
                          {
                            title: `Health`,
                            detail: _getHealthClaims(a.mutable_data?.CBH)
                              .health,
                          },
                          {
                            title: `Cubolands`,
                            detail: !a.mutable_data?.cubolands
                              ? 0
                              : a.mutable_data?.cubolands,
                          },
                          {
                            title: `Health Deduction Per Claim`,
                            detail: _getHealthCost(cardLevel, config),
                          },
                          {
                            title: `Cubo Cost to Level Up`,
                            detail: _getCuboCost(cardLevel, config),
                          },
                          {
                            title: `Bit Cost to Level Up`,
                            detail: _getBitCost(cardLevel, config),
                          },
                          {
                            title: `Recharge Cost`,
                            detail: "100 BITS",
                          },
                        ]}
                        detail={`Lvl ${cardLevel}`}
                        imagePath={`${IpfsImageUrl}/${a?.data?.img}`}
                        rarity={a?.data?.name}
                        buttonText={"CLAIM"}
                        onOverlayButtonClicked={async () => {
                          const transactionData = [
                            {
                              contractAccount: contract,
                              actionName: "rechargegs",
                              data: {
                                asset_ids: [parseInt(a.asset_id)],
                                player: activeUser,
                              },
                            },
                          ];
                          const rechargeResponse = await transactionSigner(
                            userProperties,
                            transactionData
                          );
                          if (rechargeResponse.success) {
                            const assetIndex = assets.findIndex(
                              (ai) => ai.asset_id === a.asset_id
                            );

                            const healthClaims = _getHealthClaims(
                              a.mutable_data?.CBH
                            );

                            const updatedCBH = `${healthClaims.claims},${100}`;
                            assets[assetIndex].mutable_data.CBH = updatedCBH;

                            setCardPositive(true);
                            setCardModalMessage("Recharge Success");
                            return;
                          }
                          setCardPositive(false);
                          setCardModalMessage(
                            rechargeResponse?.responseData.message
                          );
                        }}
                        onHealButtonClicked={async () => {
                          const transactionData = [
                            {
                              contractAccount: contract,
                              actionName: "rechargegs",
                              data: {
                                asset_ids: [parseInt(a.asset_id)],
                                player: activeUser,
                              },
                            },
                          ];
                          const healResponse = await transactionSigner(
                            userProperties,
                            transactionData
                          );
                          if (healResponse.success) {
                            const assetIndex = assets.findIndex(
                              (ai) => ai.asset_id === a.asset_id
                            );

                            const healthClaims = _getHealthClaims(
                              a.mutable_data?.CBH
                            );

                            const updatedCBH = `${healthClaims.claims},${100}`;
                            assets[assetIndex].mutable_data.CBH = updatedCBH;

                            setCardPositive(true);
                            setCardModalMessage("Heal Success");
                            return;
                          }
                          setCardPositive(false);
                          setCardModalMessage(
                            healResponse?.responseData.message
                          );
                        }}
                        onUpgradeButtonClicked={async () => {
                          const transactionData = [
                            {
                              contractAccount: contract,
                              actionName: "upgradegs",
                              data: {
                                asset_ids: [parseInt(a.asset_id)],
                                player: activeUser,
                              },
                            },
                          ];
                          const upgradeResponse = await transactionSigner(
                            userProperties,
                            transactionData
                          );
                          if (upgradeResponse.success) {
                            const assetIndex = assets.findIndex(
                              (ai) => ai.asset_id === a.asset_id
                            );

                            const updatedLevel = assets[assetIndex].mutable_data
                              ?.Level
                              ? parseInt(
                                  assets[assetIndex].mutable_data.Level
                                ) + 1
                              : 2;

                            assets[assetIndex].mutable_data.Level =
                              updatedLevel;

                            setCardPositive(true);
                            setCardModalMessage("Upgrade Success");
                            return;
                          }
                          setCardPositive(false);
                          setCardModalMessage(
                            upgradeResponse?.responseData.message
                          );
                        }}
                        onButtonClick={async () => {
                          const response = await _handleClaimReward(
                            userProperties,
                            contract,
                            [parseInt(cardDetail?.asset_id)]
                          );

                          if (response.success) {
                            const assetIndex = assets.findIndex(
                              (ai) => ai.asset_id === a.asset_id
                            );

                            const healthCost = _getHealthCost(
                              cardLevel,
                              config
                            );
                            const healthClaims = _getHealthClaims(
                              a.mutable_data?.CBH
                            );

                            const updatedHealth =
                              healthClaims.health - healthCost >= 0
                                ? healthClaims.health - healthCost
                                : 0;

                            const updatedCBH = `${
                              healthClaims.claims + 1
                            },${updatedHealth}`;

                            assets[assetIndex].mutable_data.CBH = updatedCBH;

                            setCardPositive(true);
                            setCardModalMessage("Claim Successful");
                            return;
                          }

                          setCardPositive(false);
                          setCardModalMessage(response?.responseData.message);
                        }}
                      >
                        <li className="bid mt-1">
                          <button
                            className={`btn ${buttonOverlayClass}`}
                            style={{ minWidth: "100%" }}
                            onClick={() => setStakedGamer(cardDetail)}
                          >
                            VIEW PCS
                          </button>
                        </li>
                        <hr />
                        <li className="bid">
                          <button
                            className={`btn ${buttonOverlayClass}`}
                            style={{ minWidth: "100%" }}
                            onClick={async () => {
                              const response = await _handleDeactivateGamer(
                                userProperties,
                                activeUser,
                                contract,
                                a.asset_id
                              );

                              if (response.success) {
                                const filteredStakedGamers =
                                  stakedGamers.filter(
                                    (g) => g.asset_id !== a.asset_id
                                  );

                                setCardPositive(true);
                                setCardModalMessage(
                                  `Gamer Deactivation Successful`
                                );
                                setStakedGamers(filteredStakedGamers);
                                return;
                              }

                              setCardPositive(false);
                              setCardModalMessage(
                                response?.responseData.message
                              );
                            }}
                          >
                            Deactivate
                          </button>
                        </li>
                      </CollectionItemCardBtnDetails>
                    </div>
                  );
                })}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={exchangeModal}
        modalMessage={exchangeModalMessage}
        messagePositive={exchangePositive}
        className="mining-page__modal-content"
        onModalClose={() => {
          setExchangeModal(false);
          setExchangeBits(false);
          setExchangeModalMessage("");
          setExchangeValue(0);
        }}
      >
        <div className="mining-page__modal-body">
          <div className="header-form mining-page__modal-input">
            <form>
              <label className="mining-page__text--white">
                How many {exchangeBits ? `LANBX` : `BITS`} will you like to
                sell?
              </label>
              <input
                step={`any`}
                type="text"
                className="txt"
                value={exchangeValue ? exchangeValue : ""}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const isValid = isDecimal(inputValue);
                  const isZero = e.target.value === 0;

                  if (!inputValue) setExchangeValue(0);
                  if (isValid || isZero) setExchangeValue(inputValue);
                }}
              />
            </form>
          </div>
        </div>

        {!balances.length ? null : (
          <ProductList
            centered
            title="Your Current Balance"
            className="author-product-meta"
            productList={[balances, lanbxBalances]}
          ></ProductList>
        )}

        {!config ? null : (
          <div className="mining-page__modal-notes">
            <label className="mining-page__text--white">
              Current Exchange Rate: 1 LANBX = {config[0].swap_rate} <hr></hr>
              The exchange rate is a variable rate and can change over time.
            </label>
          </div>
        )}

        <div className="mining-page__modal-actions">
          {exchangeBits ? (
            <button
              disabled={!exchangeValue}
              className="mining-page__modal-btn btn"
              onClick={async () => {
                const transactionData = [
                  {
                    contractAccount: landboxContract,
                    actionName: "transfer",
                    data: {
                      from: activeUser,
                      to: contract,
                      quantity: `${getToFixedNumber(exchangeValue, 4)} LANBX`,
                      memo: "swap",
                    },
                  },
                ];

                const response = await transactionSigner(
                  userProperties,
                  transactionData
                );

                if (response.success) {
                  setExchangeModalMessage("Your exchange was successful.");
                  setExchangePositive(true);
                  return;
                }

                setExchangeModalMessage(response?.responseData?.message);
                setExchangePositive(false);
              }}
            >
              Exchange For BITS
            </button>
          ) : (
            <button
              disabled={!exchangeValue}
              className="mining-page__modal-btn btn"
              onClick={async () => {
                const transactionData = [
                  {
                    contractAccount: contract,
                    actionName: "swaptkn",
                    data: {
                      player: activeUser,
                      quantity: `${getToFixedNumber(exchangeValue, 4)} BITS`,
                    },
                  },
                ];

                const response = await transactionSigner(
                  userProperties,
                  transactionData
                );

                if (response.success) {
                  setExchangeModalMessage("Your exchange was successful.");
                  setExchangePositive(true);
                  return;
                }

                setExchangeModalMessage(response?.responseData?.message);
                setExchangePositive(false);
              }}
            >
              Exchange For LANBX
            </button>
          )}
          <div>
            <button
              className="btn mining-page__secondary-btn"
              onClick={() => {
                setExchangeBits(!exchangeBits);
                setExchangeModalMessage("");
                setExchangeValue(0);
              }}
            >
              {exchangeBits ? "SWAP TO BITS" : "SWAP TO LANBX"}
            </button>
          </div>
        </div>
      </Modal>
      <section className="market-single-area">
        <div className="container">
<div className="row justify-content-center">
<div className="col-6">
<h2 className="title">Unclaimed Mining Bits</h2>
  <div className="alert alert-success" role="alert">
    <h1 style={{ textAlign: 'center', paddingTop: '5px' }}> {unclaimedMiningRate} BITS</h1>
  </div>
</div>

<div className="col-6">
  
    <>
      <h2 className="title">Tips</h2>
      <div   className="alert alert-success"
  role="alert"
  style={{ backgroundColor: '#241F3D' }}>

    <p 
  style={{ 
    textAlign: 'center', 
    paddingTop: '5px', 
    paddingBottom: '5px', // Add bottom padding
    paddingLeft: '5px', // Add left padding
    paddingRight: '5px' // Add right padding
  }}
>Tip #1: {nextClaimMessage}</p>

       {lanbxBalances && parseFloat(removeSuffx(lanbxBalances, /LANBX/gi)) >= 1000 && ( <p 
  style={{ 
    textAlign: 'center', 
    paddingTop: '5px', 
    paddingBottom: '5px', // Add bottom padding
    paddingLeft: '5px', // Add left padding
    paddingRight: '5px' // Add right padding
  }}
>
         Tip #2: You can now buy {Math.floor(parseFloat(removeSuffx(lanbxBalances, /LANBX/gi)) / 1000)} GEN1 Computer Mining Packs.

        </p>  )}
      </div>
    </>
 
</div>

<div className="col-12"><hr /></div>

<div className="col-md-2 text-center">
    <button className="mining-page__header-btn" onClick={async () => {
      const assets = stakedGamers
        .filter((g) => g.owner === activeUser)
        .map((a) => parseInt(a.asset_id));

      const claimResponse = await _claimRewards(userProperties, contract, assets);

      if (claimResponse.success) {
        setPopupSuccess(true);
        setPopupMessage(`Claimed Mining Bits`);
        return;
      }

      setPopupSuccess(false);
      setPopupMessage(claimResponse?.responseData.message);
    }}>
      <img src="assets/img/mn-8.png" alt="claim" />
      <p>Claim All</p>
    </button>
</div>

<div className="col-md-2 text-center">
                <button className="mining-page__header-btn" onClick={() => setExchangeModal(true)}>
                    <img src="assets/img/mn-1.png" alt="exchange" />
                
                <p>Exchange Center</p></button>
            </div>

<div className="col-md-2 text-center">
                             <button
                  className="mining-page__header-btn"
                  onClick={() => setCardModal(true)}
                >
                    <img src="assets/img/mn-2.png" alt="exchange" />
                <p>Staking Area</p></button>
            </div>


<div className="col-md-2 text-center">
                             <a href="https://neftyblocks.com/collection/landboxgames/drops/211225">
 <button className="mining-page__header-btn">
                    <img src="assets/img/mn-3.png" alt="exchange" />
                <p>GEN1 Miners Drop</p></button>
</a>
            </div>

<div className="col-md-2 text-center">
                             <a href="https://neftyblocks.com/collection/landboxgames/blends/blend.nefty/29712">
 <button className="mining-page__header-btn">
                    <img src="assets/img/mn-4.png" alt="exchange" />
                <p>GEN2 Miners Blend</p></button>
</a>
            </div>


<div className="col-md-2 text-center">
                             <a href="https://landboxio.gitbook.io/landbox-game-guide/available-modes#2d-computer-mining">
 <button className="mining-page__header-btn">
                    <img src="assets/img/mn-6.png" alt="exchange" />
                <p>Mining Guide</p></button>
</a>
            </div>



            <div className="col-lg-12">
              <div className="market-single-top">
                <div className="market-single-title-wrap">
                  <h2 className="title">
                    Currently Mining
                    <button
                      className="mining-page__header-btn"
                      onClick={() => setExchangeModal(true)}
                    >
                      <img
                        className="mining-page__header-icon"
                        src="assets/img/icons/exchange-icon.png"
                        alt="header-icon"
                      />
                    </button>
                  </h2>
                </div>
                <div className="market-single-action"></div>
              </div>

              <div className="container-fluid dashboard">
                <div className="row" id="draggablePanelList">
                  <div className="col-sm-6 mb-2">
                    <div className="panel alert">
                      <div className="panel-heading">&nbsp;</div>
                      <div className="panel-body alert">
                        <div className="number">
                          {getToFixedNumber(
                            miningPower +
                              miningPower *
                                (getMiningBonus(
                                  parseInt(removeSuffx(balances, /BITS/gi))
                                ) *
                                  0.01),
                            4
                          )} BITS/HR
                        </div>
                      </div>
                      <div className="panel-footer">
                        <div className="title2">{} Base: {getToFixedNumber(miningPower, 4)} BITS/HR</div>
                      </div>
                    </div>
                  </div>


                  <div className="col-sm-6 mb-2">
                    <div className="panel">
                      <div className="panel-heading">&nbsp;</div>
                      <div className="panel-body">
                        <div className="number">
{removeSuffx(lanbxBalances, /LANBX/gi)} $LANBX
                        </div>
                      </div>
                      <div className="panel-footer">
                        <div className="title2 mining-page__background--violet">
							<a href="https://alcor.exchange/trade/lanbx-landbox.gm_wax-eosio.token" style={{ color: '#afaeb1' }}>Exchange on Alcor</a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 mb-2">
                    <div className="panel">
                      <div className="panel-heading">&nbsp;</div>
                      <div className="panel-body">
                        <div className="number">
                          {removeSuffx(balances, /BITS/gi)}
                        </div>
                      </div>
                      <div className="panel-footer">
                        <div className="title2 mining-page__background--violet">
                          BITS
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="panel">
                      <div className="panel-heading">&nbsp;</div>
                      <div className="panel-body">
                        <div className="number">
                          {`${getMiningBonus(
                            parseInt(removeSuffx(balances, /BITS/gi))
                          )} %`} BONUS
                        </div>
                      </div>
                      <div className="panel-footer">
                        <div className="title2 mining-page__background--violet">
                         <a href="https://landboxio.gitbook.io/landbox-game-guide/available-modes#breakdown-of-mining-bits-bonus-per-hour" style={{ color: '#afaeb1' }}>Increase Your Bonus?</a>
                        </div>
                      </div>
                    </div>
                  </div>

{/* Panel for Staked Gamers */}
  <div className="col-sm-6 mb-2">
    <div className="panel">
      <div className="panel-heading">&nbsp;</div>
      <div className="panel-body">
        <div className="number">
          {stakedGamers
            ? stakedGamers.filter((g) => g.owner === activeUser).length
            : 0}
        </div>
      </div>
      <div className="panel-footer">
        <div className="title2 mining-page__background--violet">
          Staked Gamers
        </div>
      </div>
    </div>
  </div>

  {/* Panel for Staked PCs */}
  <div className="col-sm-6 mb-2">
    <div className="panel">
      <div className="panel-heading">&nbsp;</div>
      <div className="panel-body">
        <div className="number">
          {stakedPCs
            ? stakedPCs.filter((p) => p.owner === activeUser).length
            : 0}
        </div>
      </div>
      <div className="panel-footer">
        <div className="title2 mining-page__background--violet">
          Staked PCs
        </div>
      </div>
    </div>
  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="activity-table-responsive">
<hr />
                <h2>GAMER NFTs</h2>
                <div className="mt-3 row">
                  {assets.length &&
                    assets
                      .filter((a) => {
                        const templateData =
                          templates &&
                          templates.find(
                            (t) =>
                              t.template_id === parseInt(a.template.template_id)
                          );

                        const cardDetails = stakedGamers.find(
                          (g) => g.asset_id === a.asset_id
                        );

                        const _hasMaxPcs =
                          cardDetails && templateData
                            ? cardDetails?.pcids.length >= templateData?.max_pcs
                            : false;

                        const _isAGamer = templateData
                          ? templateData.type ===
                            miningPageConstants.types.gamer
                          : false;

                        return (
                          (!cardDetails || (cardDetails && !_hasMaxPcs)) &&
                          templateData &&
                          _isAGamer
                        );
                      })
                      .map((a, i) => {
                        const _isStaked = stakedGamers.find(
                          (sg) => sg.asset_id === a.asset_id
                        );

                        const cardLevel = a.mutable_data?.Level
                          ? a.mutable_data?.Level
                          : "1";

                        return (
                          <div className="col-xl-3 col-md-4 col-sm-6">
                            <CollectionItemCard
                              key={i}
                              secondaryBtn={_isStaked}
                              detail={`Lvl ${cardLevel}`}
                              imagePath={`${IpfsImageUrl}/${a?.data?.img}`}
                              rarity={a?.data?.name}
                              buttonText={_isStaked ? "ASSIGN CPU" : "ACTIVATE"}
                              secondButtonText="Deactivate"
                              onButtonClick={async () => {
                                if (_isStaked) {
                                  setGamer(a);
                                  return;
                                }

                                const response = await _handleActivateGamer(
                                  userProperties,
                                  activeUser,
                                  contract,
                                  a.asset_id
                                );

                                if (response.success) {
                                  setPopupMessage(
                                    `Gamer Registration Successful`
                                  );
                                  const gamerLevel = a.mutable_data?.Level
                                    ? parseInt(a.mutable_data?.Level)
                                    : 1;

                                  const gamerData = {
                                    asset_id: a.asset_id,
                                    owner: activeUser,
                                    pcids: [],
                                    level: gamerLevel,
                                  };

                                  setStakedGamers([...stakedGamers, gamerData]);
                                  setPopupSuccess(true);
                                  setPopupMessage(
                                    `Gamer Registration Successful`
                                  );
                                  return;
                                }

                                setPopupSuccess(false);
                                setPopupMessage(response?.responseData.message);
                              }}
                              onSecondButtonClick={async () => {
                                const response = await _handleDeactivateGamer(
                                  userProperties,
                                  activeUser,
                                  contract,
                                  a.asset_id
                                );

                                if (response.success) {
                                  const filteredGamers = stakedGamers.filter(
                                    (sg) => sg.asset_id !== a.asset_id
                                  );

                                  setStakedGamers(filteredGamers);
                                  setPopupSuccess(true);
                                  setPopupMessage(
                                    `Gamer Deactivation Successful`
                                  );
                                  return;
                                }

                                setPopupSuccess(true);
                                setPopupMessage(response?.responseData.message);
                                return;
                              }}
                            />
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="author-profile-area">
          <div className="container">
            <div className="row justify-content-center">

              <div className="col-xl-12 col-lg-12">
                {!balances.length ? null : (
                  <ProductList
                    className="author-product-meta"
                    productList={[balances, lanbxBalances]}
                    list={[`${getToFixedNumber(miningPower)} BITS/HR`]}
                  />
                )}

                <div className="row justify-content-center">
                  {assets.length &&
                    assets
                      .filter((a) => {
                        const templateData = templates.find(
                          (t) =>
                            t.template_id === parseInt(a?.template?.template_id)
                        );

                        return (
                          templateData &&
                          templateData?.type === miningPageConstants.types.pc
                        );
                      })
                      .map((a, i) => {
                        const _isPCStaked = stakedPCs.find((sp) => {
                          const parsedAssetId = parseInt(a.asset_id);
                          const parsedStakedAssetId = parseInt(sp.asset_id);

                          return (
                            parsedAssetId === parsedStakedAssetId &&
                            sp.owner === activeUser
                          );
                        });

                        const templateData = templates.find((t) => {
                          return (
                            t.template_id === parseInt(a.template.template_id)
                          );
                        });

                        const _cardLevel = a.mutable_data?.Level
                          ? parseInt(a.mutable_data?.Level)
                          : 1;

                        const _getUpgradeCost = (cardLevel, templateData) => {
                          const defaultUpgradeLevel = 2;
                          const upgradeLevel = cardLevel
                            ? cardLevel + 1
                            : defaultUpgradeLevel;

                          if (templateData && templateData.levelcost.length) {
                            const levelCost = templateData.levelcost.find(
                              (d) => upgradeLevel === parseInt(d?.key)
                            );

                            return levelCost
                              ? getToFixedNumber(levelCost.value)
                              : 0;
                          }

                          return 0;
                        };

                        return (
                          <div className="col-xl-3 col-md-3 col-sm-3">
                            <CollectionItemCardPC
                              key={i}
                              button={_isPCStaked}
                              detail={`Lvl ${_cardLevel}`}
                              imagePath={`${IpfsImageUrl}/${a?.data?.img}`}
                              rarity={a?.data?.name}
                              upgradeCost={`${_getUpgradeCost(
                                _cardLevel,
                                templateData
                              )} BITS`}
                              onButtonClick={async () => {
                                const transactionData = [
                                  {
                                    contractAccount: contract,
                                    actionName: "upgradepcs",
                                    data: {
                                      asset_ids: [parseInt(a.asset_id)],
                                      player: activeUser,
                                    },
                                  },
                                ];

                                const upgradeResponse = await transactionSigner(
                                  userProperties,
                                  transactionData
                                );

                                if (upgradeResponse.success) {
                                  setPopupSuccess(true);
                                  setPopupMessage("Upgrade Success");
                                  return;
                                }
                                setPopupSuccess(false);
                                setPopupMessage(
                                  upgradeResponse?.responseData.message
                                );
                              }}
                            />
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </LandBoxContainer>
  );
}

async function _handleClaimReward(userProperties, contract, assets) {
  const transactionData = [
    {
      contractAccount: contract,
      actionName: "claimgs",
      data: {
        asset_ids: assets,
      },
    },
  ];

  return await transactionSigner(userProperties, transactionData);
}

async function _handleActivateGamer(
  userProperties,
  activeUser,
  contract,
  assetId
) {
  const transactionData = [
    {
      contractAccount: contract,
      actionName: "reggs",
      data: {
        asset_ids: [parseInt(assetId)],
        player: activeUser,
      },
    },
  ];

  const registerGamerResponse = await transactionSigner(
    userProperties,
    transactionData
  );

  return registerGamerResponse;
}

async function _handleDeactivateGamer(
  userProperties,
  activeUser,
  contract,
  assetId
) {
  const transactionData = [
    {
      contractAccount: contract,
      actionName: "dereggs",
      data: {
        asset_ids: [parseInt(assetId)],
        player: activeUser,
      },
    },
  ];

  return await transactionSigner(userProperties, transactionData);
}

async function _handleActivatePC(
  userProperties,
  activeUser,
  contract,
  assetId,
  gamerId
) {
  const transactionData = [
    {
      contractAccount: contract,
      actionName: "regpc",
      data: {
        asset_ids: [parseInt(assetId)],
        gamer_id: gamerId,
        player: activeUser,
      },
    },
  ];

  return await transactionSigner(userProperties, transactionData);
}

async function _handleDeactivatePC(
  userProperties,
  activeUser,
  contract,
  assetId
) {
  const transactionData = [
    {
      contractAccount: contract,
      actionName: "deregpc",
      data: {
        asset_ids: [parseInt(assetId)],
        player: activeUser,
      },
    },
  ];

  return await transactionSigner(userProperties, transactionData);
}

async function _claimRewards(userProperties, contract, assets) {
  const transactionData = [
    {
      contractAccount: contract,
      actionName: "claimgs",
      data: {
        asset_ids: assets,
      },
    },
  ];

  return await transactionSigner(userProperties, transactionData);
}
