import React from "react";

export function BidWrapperContainer({
  title,
  subTitle,
  imagePath,
  iconImagePath = "assets/img/puter.png",
  buttonText = "Buy a Pack",
  onButtonClick,
}) {
  return (
    <div className="highest-bid-wrap">
      <div className="row">
        <div className="col-xl-6 col-lg-12 col-md-6">
          <h5 className="title">{title}</h5>
          <div className="highest-bid-avatar">
            <div className="thumb">
              <img src={iconImagePath} alt="" />
            </div>
            <div className="content">
              <h5 className="title">{title}</h5>
              <span>
                {!imagePath ? null : (
                  <img src={imagePath} alt="token" style={{ width: "20px" }} />
                )}
                {subTitle}
              </span>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-6">
          <button className="place-bid-btn" onClick={onButtonClick}>
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}

export function SectionContainer({ className = "", children }) {
  return (
    <section className={className}>
      <div className="container">{children}</div>
    </section>
  );
}

export function TopSellerContainer({ children }) {
  return (
    <div className="top-seller-area">
      <div className="container">
        <div className="top-seller-wrap">{children}</div>
      </div>
    </div>
  );
}

export function WeeklyFeaturedContainer({ children }) {
  return (
    <section className="week-features-area">
      <div className="container">{children}</div>
    </section>
  );
}
