export default async function transactionSigner(
  userProperties,
  transactionData
) {
  var result = null;

  const transactionDetails = transactionData.map((t, i) => {
    const account = Array.isArray(transactionData[i])
      ? transactionData[i][0].contractAccount
      : transactionData[i].contractAccount;

    const name = Array.isArray(transactionData[i])
      ? transactionData[i][0].actionName
      : transactionData[i].actionName;

    const authorization = [
      {
        actor: userProperties.accountName,
        permission: userProperties.requestPermission,
      },
    ];

    const data = Array.isArray(transactionData[i])
      ? transactionData[i][0].data
      : transactionData[i].data;

    return { account, name, authorization, data };
  });

  console.log("😎😎", transactionDetails);

  try {
    result = await userProperties.signTransaction(
      {
        actions: transactionDetails,
      },
      {
        blocksBehind: 3,
        expireSeconds: 120,
      }
    );

    return { success: true, responseData: result };
  } catch (e) {
    console.log("🎶🎶🎶", e);
    return { success: false, responseData: e };
  }
}
