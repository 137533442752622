import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

import "./index.scss";

export default function Header({ activeUser, showWaxModal, onUserLogout }) {
  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }

    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      var mobileMenuContent = $(".menu-area .push-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );

      $(".menu-backdrop, .mobile-menu .close-btn").click(() => {
        $("body").removeClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });
    }
  }, []);

  useEffect(() => {
    $(".menu-tigger").on("click", function () {
      $(".extra-info,.offcanvas-overly").addClass("active");
      return false;
    });
    $(".menu-close,.offcanvas-overly").on("click", function () {
      $(".extra-info,.offcanvas-overly").removeClass("active");
    });

    /*=============================================
	=     Menu sticky & Scroll to top      =
=============================================*/
    $(window).on("scroll", function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $(".scroll-to-target").removeClass("open");
        $("#header-top-fixed").removeClass("header-fixed-position");
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $(".scroll-to-target").addClass("open");
        $("#header-top-fixed").addClass("header-fixed-position");
      }
    });

    /*=============================================
	=    		 Scroll Up  	         =
=============================================*/
    if ($(".scroll-to-target").length) {
      $(".scroll-to-target").on("click", function () {
        var target = $(this).attr("data-target");
        // animate
        $("html, body").animate(
          {
            scrollTop: $(target).offset().top,
          },
          1000
        );
      });
    }
  }, []);

  return (
    <header>
      <div id="sticky-header" className="menu-area ">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler">
                <i className="fas fa-bars" />
              </div>
              <div className="menu-wrap main-menu">
                <nav className="menu-nav">
                  <div className="logo">
                    <a href="/">
                      <img
                        src="/assets/img/landbox-logo-website.png"
                        alt="LandBox"
                      />
                    </a>
                  </div>
                  <div className="navbar-wrap push-menu main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/mining">Mining</Link>
                      </li>
	  <li>
                        <Link to="/farming">Farming</Link>
                      </li>
                      <li>
                        <Link to="/drops">Drops</Link>
                      </li>
                      <li>
                        <Link to="/marketplace">Marketplace</Link>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://rebornmonster.com/"
                        >
                          MF: Reborn
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://landboxgame.com/play"
                          target="_new"
                        >
                          Play LandBox Game
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="header-action d-none d-md-block">
                    <ul>
                      <li className="header-btn">
                        {activeUser ? (
                          <button
                            className="btn"
                            onClick={() => {
                              onUserLogout();
                            }}
                          >
                            {activeUser}
                          </button>
                        ) : (
                          <button
                            className="btn"
                            onClick={() => {
                              showWaxModal();
                            }}
                          >
                            Wallet Connect
                          </button>
                        )}
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              {/* Mobile Menu  */}

              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times" />
                  </div>
                  <div className="nav-logo">
                    <a href="/">
                      <img
                        src="/assets/img/landbox-logo-website.png"
                        alt=""
                        title="true"
                      />
                    </a>
                    <hr></hr>
                    <ul>
                      <li className="header-btn">
                        {activeUser ? (
                          <button
                            className="btn"
                            onClick={() => {
                              onUserLogout();
                            }}
                          >
                            {activeUser}
                          </button>
                        ) : (
                          <button
                            className="btn"
                            onClick={() => {
                              showWaxModal();
                            }}
                          >
                            Wallet Connect
                          </button>
                        )}
                      </li>
                    </ul>
                  </div>
                  <div className="menu-outer">
                    {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" />
              {/* End Mobile Menu */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
