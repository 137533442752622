import { Link } from "react-router-dom";
import classNames from "classnames";

import "./index.scss";

export function AuthorProfileCard({
  imagePath,
  title,
  titleImagePath,
  subTitle,
  buttonText = "Click",
  onButtonClick,
  children,
}) {
  return (
    <aside className="author-profile-wrap">
      <div className="author-profile-thumb">
        <img src={imagePath} alt="" />
      </div>
      <div className="author-info">
        <h5 className="title">
          {title}
          {!titleImagePath ? null : <img src={titleImagePath} alt="" />}
        </h5>
        {!subTitle ? null : <span>{subTitle}</span>}
      </div>

      <div className="author-social">
        <button className="place-bid-btn" onClick={onButtonClick}>
          {buttonText}
        </button>
        <hr></hr>
        {children}
      </div>
    </aside>
  );
}

export function CollectionItemCard({
  className = "",
  selected = false,
  button = true,
  secondaryBtn = false,
  iconImagePath = "assets/img/upgradez.png",
  imagePath,
  detail = "",
  upgradeTitle = "",
  upgradeText = "",
  rarity,
  floatingHeader,
  upgradeCost,
  buttonText = "Upgrade",
  secondButtonText = "Click",
  onButtonClick,
  onSecondButtonClick,
  children,
}) {
  const selectedClass = classNames({
    "card--selected": selected,
  });

  return (
    <div className={`top-collection-item ${className} ${selectedClass}`}>
      <div className="collection-item-top">
        <ul>
          <li className="avatar">
            <a href="#" className="thumb">
              <img src={iconImagePath} alt="" />{" "}
            </a>
            {detail}
            <span className="name">{rarity}</span>
          </li>
          {!floatingHeader ? null : (
            <li
              className="avatar"
              style={{
                border: "1px solid green",
                borderRadius: "50%",
                padding: "0.5em",
              }}
            >
              {floatingHeader}
            </li>
          )}
        </ul>
      </div>
      <div className="collection-item-thumb">
        <a href="/">
          <img src={imagePath} alt="" />
        </a>
      </div>
      {!upgradeCost ? null : (
        <div className="collection-item-content">
          <h5 className="title">
            <a href="#">upgradeCost</a>{" "}
            <span className="price">{upgradeCost}</span>
          </h5>
        </div>
      )}

      {!upgradeTitle ? null : (
        <div className="collection-item-content">
          <h5 className="title">
            <a href="#">{upgradeTitle}</a>{" "}
            <span className="price">{upgradeText}</span>
          </h5>
        </div>
      )}
      <div className="collection-item-bottom">
        <ul>
          <li className="bid">
            {button && (
              <button className="btn" onClick={onButtonClick}>
                {buttonText}
              </button>
            )}
            {/* {!button ? null : (
              <button className="btn" onClick={onButtonClick}>
                {buttonText}
              </button>
            )} */}
          </li>
          {!secondaryBtn ? null : (
            <li className="bid">
              <button className="btn" onClick={onSecondButtonClick}>
                {secondButtonText}
              </button>
            </li>
          )}
        </ul>
        {children}
      </div>
    </div>
  );
}

export function CollectionItemCardPC({
  className = "",
  selected = false,
  button,
  secondaryBtn = false,
  iconImagePath = "assets/img/upgradez.png",
  imagePath,
  detail = "",
  upgradeTitle = "",
  upgradeText = "",
  rarity,
  floatingHeader,
  upgradeCost,
  buttonText = "Upgrade",
  secondButtonText = "Click",
  onButtonClick,
  onSecondButtonClick,
  children,
}) {
  const selectedClass = classNames({
    "card--selected": selected,
  });

  return (
    <div className={`top-collection-item ${className} ${selectedClass}`}>
      <div className="collection-item-top">
        <ul>
          <li className="avatar">
            <a href="#" className="thumb">
              <img src={iconImagePath} alt="" />{" "}
            </a>
            {detail}
            <span className="name">{rarity}</span>
          </li>
          {!floatingHeader ? null : (
            <li
              className="avatar"
              style={{
                border: "1px solid green",
                borderRadius: "50%",
                padding: "0.5em",
              }}
            >
              {floatingHeader}
            </li>
          )}
        </ul>
      </div>
      <div className="collection-item-thumb">
        <a href="/">
          <img src={imagePath} alt="" />
        </a>
      </div>
      {!upgradeCost ? null : (
        <div className="collection-item-content">
          <h5 className="title">
            <a href="#">upgradeCost</a>{" "}
            <span className="price">{upgradeCost}</span>
          </h5>
        </div>
      )}

      {!upgradeTitle ? null : (
        <div className="collection-item-content">
          <h5 className="title">
            <a href="#">{upgradeTitle}</a>{" "}
            <span className="price">{upgradeText}</span>
          </h5>
        </div>
      )}
      <div className="collection-item-bottom">
        <ul>
          <li className="bid">
            {button && (
              <button className="btn" onClick={onButtonClick}>
                {buttonText}
              </button>
            )}
            {/* {!button ? null : (
              <button className="btn" onClick={onButtonClick}>
                {buttonText}
              </button>
            )} */}
          </li>
          {!secondaryBtn ? null : (
            <li className="bid">
              <button className="btn" onClick={onSecondButtonClick}>
                {secondButtonText}
              </button>
            </li>
          )}
        </ul>
        {children}
      </div>
    </div>
  );
}

export function CollectionItemCardBtnDetails({
  className = "",
  button = true,
  overlay = false,
  healBtn = false,
  upgradeBtn = false,
  secondaryBtn = false,
  selected = false,
  iconImagePath = "assets/img/upgradez.png",
  imagePath,
  detail = "",
  rarity,
  floatingHeader,
  upgradeDetail = [],
  upgradeCost,
  overlayColor = "",
  overlayButtonText = "",
  buttonText = "Upgrade",
  secondButtonText = "Click",
  onButtonClick,
  onOverlayButtonClicked,
  onHealButtonClicked,
  onUpgradeButtonClicked,
  onSecondButtonClicked,
  children,
}) {
  const selectedClass = classNames({
    "card--selected": selected,
  });

  const overlayClass = classNames({
    "card__overlay--gray": overlay && overlayColor === "gray",
  });

  const imageOverlayClass = classNames({
    "card__image--gray": overlay && overlayColor === "gray",
  });

  const buttonOverlayClass = classNames({
    "card__button--gray": overlay && overlayColor === "gray",
  });

  const textOverlayClass = classNames({
    "card__text--gray": overlay && overlayColor === "gray",
  });

  return (
    <div
      className={`top-collection-item ${className} ${selectedClass} ${overlayClass}`}
    >
      <div className="collection-item-top">
        <ul>
          <li className="avatar">
            <a href="#" className="thumb">
              <img
                className={`${imageOverlayClass}`}
                src={iconImagePath}
                alt=""
              />{" "}
            </a>
            <span className={textOverlayClass}>{detail}</span>
            <span className="name">{rarity}</span>
          </li>
          {!floatingHeader ? null : (
            <li
              className="avatar"
              style={{
                border: "1px solid green",
                borderRadius: "50%",
                padding: "0.5em",
              }}
            >
              {floatingHeader}
            </li>
          )}
        </ul>
      </div>
      <div className="collection-item-thumb">
        <a href="/">
          <img className={`${imageOverlayClass}`} src={imagePath} alt="" />
        </a>
      </div>
      {!upgradeDetail.length
        ? null
        : upgradeDetail.map((u) => {
            return (
              <div className="collection-item-content">
                <h5 className="title">
                  <a href="#">{u?.title}</a>{" "}
                  <span className={`price ${textOverlayClass}`}>
                    {u?.detail}
                  </span>
                </h5>
              </div>
            );
          })}

      {!upgradeCost ? null : (
        <div className="collection-item-content">
          <h5 className="title">
            <a href="#">upgradeCost</a>{" "}
            <span className={`price ${textOverlayClass}`}>{upgradeCost}</span>
          </h5>
        </div>
      )}

      <div className="collection-item-bottom">
        <li className="bid">
          {!overlay ? null : (
            <button
              className={`btn mt-2 ${buttonOverlayClass}`}
              style={{ minWidth: "100%" }}
              onClick={onOverlayButtonClicked}
            >
              {overlayButtonText}
            </button>
          )}

          {!button ? null : (
            <button
              className={`btn mt-2 ${buttonOverlayClass}`}
              style={{ minWidth: "100%" }}
              onClick={onButtonClick}
            >
              {buttonText}
            </button>
          )}
        </li>

        {!healBtn ? null : (
          <button
            className={`btn mt-1 ${buttonOverlayClass}`}
            style={{ minWidth: "100%" }}
            onClick={onHealButtonClicked}
          >
            Heal
          </button>
        )}

        {!upgradeBtn ? null : (
          <li className="bid">
            <button
              className={`btn mt-1 ${buttonOverlayClass}`}
              style={{ minWidth: "100%" }}
              onClick={onUpgradeButtonClicked}
            >
              ACTIVELY MINING
            </button>
          </li>
        )}

        {!secondaryBtn ? null : (
          <li className="bid">
            <button
              className={`btn ${buttonOverlayClass}`}
              onClick={onSecondButtonClicked}
            >
              {secondButtonText}
            </button>
          </li>
        )}

        {children}
      </div>
    </div>
  );
}

export function TopSellerCard({ title, description, children }) {
  return (
    <div className="top-seller-item">
      <div className="top-seller-img">
        <img src="assets/img/puter.png" alt="" />
      </div>
      <div className="top-seller-content">
        <h5 className="title">
          <a href="#">{title}</a>
        </h5>
        <p>
          {description}
          {children}
        </p>
      </div>
    </div>
  );
}

export function TopSellerCard2({ title, description, children }) {
  return (
    <div className="top-seller-item">
      <div className="top-seller-img">
        <img src="assets/img/100.png" alt="" />
      </div>
      <div className="top-seller-content">
        <h5 className="title">
          <a href="#">{title}</a>
        </h5>
        <p>
          {description}
          {children}
        </p>
      </div>
    </div>
  );
}

export function TopSellerCard3({ title, description, children }) {
  return (
    <div className="top-seller-item">
      <div className="top-seller-img">
        <img src="assets/img/world.png" alt="" />
      </div>
      <div className="top-seller-content">
        <h5 className="title">
          <a href="#">{title}</a>
        </h5>
        <p>
          {description}
          {children}
        </p>
      </div>
    </div>
  );
}

export function TopSellerCard4({ title, description, children }) {
  return (
    <div className="top-seller-item">
      <div className="top-seller-img">
        <img src="assets/img/creative.png" alt="" />
      </div>
      <div className="top-seller-content">
        <h5 className="title">
          <a href="#">{title}</a>
        </h5>
        <p>
          {description}
          {children}
        </p>
      </div>
    </div>
  );
}

export function TopSellerCard5({ title, description, children }) {
  return (
    <div className="top-seller-item">
      <div className="top-seller-img">
        <img src="assets/img/mofi.png" alt="" />
      </div>
      <div className="top-seller-content">
        <h5 className="title">
          <a href="#">{title}</a>
        </h5>
        <p>
          {description}
          {children}
        </p>
      </div>
    </div>
  );
}

export function TopSellerCard6({ title, description, children }) {
  return (
    <div className="top-seller-item">
      <div className="top-seller-img">
        <img src="assets/img/king.png" alt="" />
      </div>
      <div className="top-seller-content">
        <h5 className="title">
          <a href="#">{title}</a>
        </h5>
        <p>
          {description}
          {children}
        </p>
      </div>
    </div>
  );
}

export function TopCollectionCard({
  avatarImagePath,
  avatarLink,
  avatarName,
  cardImagePath,
  cardLink,
  cardName,
  cardPrice,
  buttonText = "Place a bid",
  onButtonClick,
  likeCount = 0,
}) {
  return (
    <div className="top-collection-item">
      <div className="collection-item-top">
        <ul>
          <li className="avatar">
            {!avatarLink ? (
              <img src={avatarImagePath} alt="" />
            ) : (
              <Link to={`/author-profile/${avatarLink}`} className="thumb">
                <img src={avatarImagePath} alt="" />
              </Link>
            )}
            By{" "}
            {!avatarLink ? (
              { avatarName }
            ) : (
              <Link to={`/author-profile/${avatarLink}`} className="name">
                {avatarName}
              </Link>
            )}
          </li>
          <li className="info-dots dropdown">
            <span />
            <span />
            <span />
            <a
              href="/#"
              className="dropdown-toggle"
              data-bs-toggle="dropdown"
              role="button"
              aria-expanded="false"
            />
            <ul className="dropdown-menu">
              <li>
                <Link to={`/nft-marketplace`}>Artwork</Link>
              </li>
              <li>
                <Link to={`/nft-marketplace`}>Action</Link>
              </li>
              <li>
                <Link to={`/nft-marketplace`}>Author Action</Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="collection-item-thumb">
        {!cardLink ? (
          <img src={cardImagePath} alt="" />
        ) : (
          <Link to={`/market-single/${cardLink}`}>
            <img src={cardImagePath} alt="" />
          </Link>
        )}
      </div>
      <div className="collection-item-content">
        <h5 className="title">
          {!cardLink ? (
            cardName
          ) : (
            <Link to={`/market-single/${cardLink}`}>{cardName}</Link>
          )}

          <span className="price">{cardPrice}</span>
        </h5>
      </div>
      <div className="collection-item-bottom">
        <ul>
          <li className="bid">
            <button className="btn" onClick={onButtonClick}>
              {buttonText}
            </button>
          </li>
          <li className="wishlist">
            <Link to="/login-register">{likeCount}</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export function CollectionCard({
  avatarImagePath,
  avatarName,
  collectionName,
  collectionColumns = 2,
  collectionGap = 5,
  likeCount = 0,
  images = [],
}) {
  return (
    <div className="week-features-item">
      <div className="features-item-top">
        <div className="features-avatar">
          <div className="features-avatar-thumb">
            <a href="/author-profile">
              <img src={avatarImagePath} alt="" />
            </a>
          </div>
          <div className="features-avatar-info">
            <h5 className="title">
              <a href="/nft-marketplace">{collectionName}</a>
            </h5>
            <span>
              Created by <a href="/author-profile">{avatarName}</a>
            </span>
          </div>
        </div>
        <div className="features-item-wishlist">
          <a href="/#">{likeCount}</a>
        </div>
      </div>
      <ul className="features-img-wrap">
        <li className="image">
          <div style={{ columns: collectionColumns, columnGap: 0 }}>
            {images.map((img, i) => (
              <img
                alt="collectionCard"
                src={img}
                key={i}
                className="image"
                style={{ padding: collectionGap / 2 }}
              />
            ))}
          </div>
        </li>
      </ul>
    </div>
  );
}

export function SubcollectionCard({
  avatarImagePath,
  avatarName,
  collectionName,
  collectionColumns = 2,
  collectionGap = 5,
  likeCount = 0,
  images = [],
}) {
  return (
    <div className="week-features-item">
      <div className="features-item-top">
        <div className="features-avatar">
          <div className="features-avatar-thumb">
            <a href="/author-profile">
              <img src={avatarImagePath} alt="" />
            </a>
          </div>
          <div className="features-avatar-info">
            <h5 className="title">
              <a href="/nft-marketplace">{collectionName}</a>
            </h5>
            <span>
              Created by <a href="/author-profile">{avatarName}</a>
            </span>
          </div>
        </div>
        <div className="features-item-wishlist">
          <a href="/#">{likeCount}</a>
        </div>
      </div>
      <ul className="features-img-wrap">
        <li className="image">
          <div style={{ columns: collectionColumns, columnGap: 0 }}>
            {images.map((img, i) => (
              <img
                alt="collectionCard"
                src={img}
                key={i}
                className="image"
                style={{ padding: collectionGap / 2 }}
              />
            ))}
          </div>
        </li>
      </ul>
    </div>
  );
}

export function NftItemCard({ header, title, detail, children, imagePath }) {
  return (
    <div className="sell-nfts-item">
      <img src={imagePath} alt="" className="icon" />
      <span className="step-count">{header}</span>
      <h5 className="title">{title}</h5>
      <p>
        {detail} {children}
      </p>
    </div>
  );
}
