import React from "react";
import { Link } from "react-router-dom";

export default function Sidebar() {
  return (
    <div className="sidebar">
      <div className="sidebar-icon">
        <ul>
          <li className="active">
            <Link to="/mining">
              <img src="assets/img/puter.png" alt="mining" />
            </Link>
          </li>
	  <li className="active">
            <Link to="/farming">
              <img src="assets/img/farmicon.png" alt="farming" />
            </Link>
          </li>
	  <li className="active">
            <a href="https://rebornmonster.com" target="_new">
              <img src="assets/img/mofi.png" alt="download" />
            </a>
          </li>
	  	  <li className="active">
            <a href="https://kingofclans.com" target="_new">
              <img src="assets/img/king.png" alt="download" />
            </a>
          </li>
	  <li className="active">
            <a href="https://launcher.elixir.app/browse/landbox" target="_new">
              <img src="assets/img/icon_dl.png" alt="download" />
            </a>
          </li>
	    <li className="active">
            <a href="/drops">
              <img src="assets/img/icon_drop.png" alt="drops" />
            </a>
          </li>
          <li className="active">
            <a href="https://neftyblocks.com/c/landboxgames/blends">
              <img src="assets/img/upgrade.png" alt="upgrading" />
            </a>
          </li>
          <li className="active">
            <a href="/marketplace">
              <img src="assets/img/marketplace.png" alt="marketplace" />
            </a>
          </li>
          <li className="active">
            <a href="https://alcor.exchange/trade/lanbx-landbox.gm_wax-eosio.token">
              <img src="assets/img/token-menu.png" alt="token" />
            </a>
          </li>
	  <li className="active">
            <a href="https://landboxio.gitbook.io/landbox-game-guide/" target="_new">
              <img src="assets/img/icon_guide.png" alt="guide" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
