const farmingPageConstants = {
  contract: "landfarm.gm",
  types: { farmer: "farmer", farm: "farm", pet: "pet" },
  exchangeType: { seeds: "seeds", flpts: "flpts", lanbx: "lanbx" },
  farmingBonus: [
    {
      farmLevel: 1,
      bonus: 0,
    },
    {
      farmLevel: 2,
      bonus: 2,
    },
    {
      farmLevel: 3,
      bonus: 4,
    },
    {
      farmLevel: 4,
      bonus: 6,
    },
    {
      farmLevel: 5,
      bonus: 8,
    },
    {
      farmLevel: 6,
      bonus: 10,
    },
    {
      farmLevel: 7,
      bonus: 12,
    },
    {
      farmLevel: 8,
      bonus: 14,
    },
    {
      farmLevel: 9,
      bonus: 16,
    },
    {
      farmLevel: 10,
      bonus: 18,
    },
  ],
};
export default farmingPageConstants;
