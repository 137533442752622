import React, { useEffect, useState } from "react";

import LandBoxContainer from "../LandBoxContainer";
import { Breadcrumb } from "../../Components/Breadcrumb";
import { Modal } from "../../Components/Modal";
import { ProductList } from "../../Components/List";
import { AuthorProfileCard, CollectionItemCard } from "../../Components/Cards";

import { getUserBalance } from "../../Services/userService";
import { getAssetCollectionByAccountName } from "../../Services/AssetsService";
import { getConfigurations } from "../../Services/FarmService";
import transactionSigner from "../../Services/TransactionSigner";

import {
  getToFixedNumber,
  isWholeNumber,
  removeSuffx,
} from "../../utils/helpers";
import farmingPageConstants from "./constant";
import waxUrl from "../../constants/waxUrl";

import "./index.scss";
import { CountdownTimer } from "../../Components/Countdown";

export default function FarmingPage({
  userProperties,
  activeUser,
  showWaxModal,
  onUserLogout,
}) {
  const { landboxContract, collectionName, IpfsImageUrl } = waxUrl;
  const { contract, exchangeType } = farmingPageConstants;

  const [gamer, setGamer] = useState("");
  const [gamerMessage, setGamerMessage] = useState("");
  const [gamerPositive, setGamerPositive] = useState(true);

  const [farmerMessage, setFarmerMessage] = useState("");
  const [farmerPositive, setFarmerPositive] = useState(true);
  const [farmerModal, setFarmerModal] = useState(false);

  const [resultsModal, setResultsModal] = useState(false);

  const [cardModal, setCardModal] = useState(false);
  const [cardModalMessage, setCardModalMessage] = useState("");
  const [cardPositive, setCardPositive] = useState(true);

  const [exchangeModal, setExchangeModal] = useState(false);
  const [exchangeModalMessage, setExchangeModalMessage] = useState("");
  const [exchangePositive, setExchangePositive] = useState(true);

  const [popupMessage, setPopupMessage] = useState("");
  const [popupSuccess, setPopupSuccess] = useState(true);

  const [assets, setAssets] = useState([]);
  const [gnomeAssets, setGnomeAssets] = useState([]);
  const [templates, setTemplates] = useState([]);

  const [balances, setBalances] = useState([]);
  const [lanbxBalances, setLanbxBalances] = useState([]);
  const [config, setConfig] = useState(null);

  const [stakedFarms, setStakedFarms] = useState([]);
  const [stakedGnomes, setStakedGnomes] = useState([]);
  const [stakedNFTs, setStakedNFTs] = useState([]);

  const [exchange, setExchange] = useState(exchangeType.seeds);
  const [exchangeBits, setExchangeBits] = useState(false);
  const [exchangeValue, setExchangeValue] = useState(0);

  const [selectedGnomes, setSelectedGnomes] = useState([]);

  const _serviceInit = async (collectionName, accountName) => {
    try {
      if (accountName) {
        const assets = await getAssetCollectionByAccountName(
          collectionName,
          accountName
        );

        const gnome = await getAssetCollectionByAccountName(
          "gnomeseries1",
          accountName
        );

        const lanbxBalance = await getUserBalance("accounts", activeUser);
        const templates = await getConfigurations("fconfigs");
        const balances = await getConfigurations(
          "balances",
          activeUser,
          activeUser
        );
        const configs = await getConfigurations("configs");
        const farms = await getConfigurations("farms", activeUser);
        const gnomes = await getConfigurations("gnomes", activeUser);
        const nfts = await getConfigurations("nfts", activeUser);

        if (lanbxBalance.success) {
          const landbxBalance = lanbxBalance.responseData?.rows;
          setLanbxBalances(landbxBalance.map((b) => b?.balance));
        }

        if (assets.success) {
          setAssets(assets.responseData?.data);
        }

        if (gnome.success) {
          setGnomeAssets(gnome.responseData?.data);
        }

        if (balances.success) {
          const bitsBalance = balances.responseData?.rows;
          setBalances(bitsBalance.map((b) => b?.balance));
        }

        if (configs.success) {
          setConfig(configs.responseData?.rows);
        }

        if (templates.success) {
          setTemplates(templates.responseData?.rows);
        }

        if (farms.success) {
          setStakedFarms(farms.responseData?.rows);
        }

        if (gnomes.success) {
          setStakedGnomes(gnomes.responseData?.rows);
        }

        if (nfts.success) {
          setStakedNFTs(nfts.responseData?.rows);
        }
      }
    } catch (error) {
      setPopupMessage(error);
      setPopupSuccess(false);
    }
  };

  useEffect(() => {
    _serviceInit(collectionName, activeUser);
  }, [collectionName, activeUser]);

  // useEffect(() => {
  //   setMiningPower(
  //     _computeMiningPower(
  //       assets,
  //       templates,
  //       config,
  //       stakedGnomes,
  //       stakedNFTs,
  //       activeUser
  //     )
  //   );
  // }, [assets, templates, config, stakedGnomes, stakedNFTs, activeUser]);

  return (
    <LandBoxContainer
      activeUser={activeUser}
      showWaxModal={showWaxModal}
      onUserLogout={onUserLogout}
    >
      <Breadcrumb page="Farming" />

      <Modal isOpen={popupMessage} className="farming-page__modal-content">
        {popupSuccess ? (
          <span className="farming-page__text--green farming-page__text--centered">
            {popupMessage}
          </span>
        ) : (
          <span className="farming-page__text--red farming-page__text--centered">
            {popupMessage}
          </span>
        )}
      </Modal>

      <Modal
        isOpen={resultsModal}
        modalTransparent={true}
        popupMessage={cardModalMessage}
        messagePositive={cardPositive}
        className="farming-page__modal-content farming-page__modal--large"
        title="Results"
        onModalClose={() => {
          setResultsModal(false);
          setCardModalMessage("");
        }}
      >
        <div className="row justify-content-center">
          <div className="farming-page__pc-grid row">
            {stakedFarms
              .filter((a) => {
                const cardDetail = assets.find(
                  (s) => a.asset_id === s.asset_id
                );

                const hasResult = a?.claim_time !== 0;

                return cardDetail && hasResult;
              })
              .map((a, i) => {
                const BITS_MULTIPLIER = 10000;

                const cardDetail = assets.find(
                  (s) => a.asset_id === s.asset_id
                );

                const cardLevel = cardDetail.mutable_data?.Level
                  ? cardDetail.mutable_data?.Level
                  : "1";

                const bitResults = a?.result / BITS_MULTIPLIER;

                return (
                  <div className="col-lg-3">
                    <CollectionItemCard
                      key={i}
                      button={false}
                      detail={`Lvl ${cardLevel}`}
                      imagePath={`${IpfsImageUrl}/${cardDetail?.data?.img}`}
                      rarity={a?.data?.name}
                    >
                      <div className="farming-page__results farming-page__results--vertical farming-page__results--centered">
                        <h6 className="farming-page__text--green">
                          CLAIM TIME
                        </h6>
                        <div className="farming-page__results-timer">
                          <CountdownTimer
                            className="farming-page__text--white"
                            timer={a?.claim_time}
                          >
                            <span className="farming-page__text--white">
                              READY TO CLAIM
                            </span>
                          </CountdownTimer>
                        </div>

                        <h6 className="farming-page__text--green">RESULT</h6>
                        <div className="farming-page__text--white">
                          {getToFixedNumber(bitResults, 4)} BITS
                        </div>
                      </div>
                    </CollectionItemCard>
                  </div>
                );
              })}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={farmerModal}
        popupMessage={farmerMessage}
        messagePositive={farmerPositive}
        className="farming-page__modal-content"
        wrapperClassName="farming-page__card-wrapper"
        onModalClose={() => {
          setFarmerModal(false);
        }}
      >
        <div className="row justify-content-center">
          <div className="farming-page__pc-grid row">
            <h3>
              GNOMES
              <div className="farming-page__burn-btn-container">
                <button
                  disabled={selectedGnomes.length <= 0}
                  className="farming-page__burn-btn place-bid-btn"
                  onClick={async () => {
                    const gnomes = selectedGnomes.map((sg) =>
                      parseInt(sg.asset_id)
                    );

                    const transactionData = [
                      {
                        contractAccount: "atomicassets",
                        actionName: "transfer",
                        data: {
                          from: activeUser,
                          to: contract,
                          asset_ids: gnomes,
                          memo: "farmlevelup",
                        },
                      },
                    ];

                    const response = await transactionSigner(
                      userProperties,
                      transactionData
                    );

                    if (response.success) {
                      const filteredGnomes = gnomeAssets.filter((ga) => {
                        const isExisting = gnomes.find(
                          (g) => parseInt(ga.asset_id) === g
                        );

                        return !isExisting;
                      });

                      setSelectedGnomes([]);
                      setGnomeAssets(filteredGnomes);
                      setFarmerMessage("GNOMES are Successfully Burned");
                      setFarmerPositive(true);
                      return;
                    }

                    setFarmerMessage(response?.responseData?.message);
                    setFarmerPositive(false);
                  }}
                >
                  BURN GNOMES
                </button>
              </div>
            </h3>
            {gnomeAssets.length &&
              gnomeAssets
                .filter((a) => {
                  const GNOME_SERIES = ["gnomecard", "series2cards"];
                  return GNOME_SERIES.find((gi) => gi === a.schema.schema_name);
                })
                .map((a, i) => {
                  const _isStaked = _isCardStaked(
                    stakedNFTs,
                    a.asset_id,
                    activeUser
                  );

                  const cardLevel = a.mutable_data?.Level
                    ? a.mutable_data?.Level
                    : "1";

                  const isCardSelected = selectedGnomes.find(
                    (sg) => sg.asset_id === a.asset_id
                  );

                  return (
                    <div className="col-lg-3">
                      <CollectionItemCard
                        key={i}
                        selected={isCardSelected}
                        secondaryBtn={_isStaked}
                        detail={`Lvl ${cardLevel}`}
                        imagePath={`${IpfsImageUrl}/${a?.data?.img}`}
                        rarity={a?.data?.name}
                        buttonText={isCardSelected ? "Selected" : "Select"}
                        onButtonClick={async () => {
                          const gnomes = selectedGnomes.filter(
                            (sg) => sg.asset_id !== a.asset_id
                          );

                          isCardSelected
                            ? setSelectedGnomes(gnomes)
                            : setSelectedGnomes([...selectedGnomes, a]);
                        }}
                      />
                    </div>
                  );
                })}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={gamer}
        popupMessage={gamerMessage}
        messagePositive={gamerPositive}
        className="farming-page__modal-content"
        wrapperClassName="farming-page__card-wrapper"
        onModalClose={() => setGamer("")}
      >
        <div className="row justify-content-center">
          <h3>SELECTED FARM</h3>
          <div className="farming-page__gamer-image">
            <img
              className="farming-page__selected-image"
              src={`${IpfsImageUrl}/${gamer?.data?.img}`}
              alt="card"
            />
          </div>
          <div className="farming-page__pc-grid row">
            <h3>NFTs</h3>
            {assets.length &&
              assets
                .filter((a) => {
                  const { farmer, pet } = farmingPageConstants.types;
                  const _isStaked = stakedNFTs.find(
                    (sn) => sn.asset_id === a.asset_id
                  );

                  const templateData = templates.find(
                    (t) => t.template_id === parseInt(a.template?.template_id)
                  );

                  return (
                    !_isStaked &&
                    templateData &&
                    (templateData?.type === farmer ||
                      templateData?.type === pet)
                  );
                })
                .map((a, i) => {
                  const _isStaked = _isCardStaked(
                    stakedNFTs,
                    a.asset_id,
                    activeUser
                  );

                  const cardLevel = a.mutable_data?.Level
                    ? a.mutable_data?.Level
                    : "1";

                  return (
                    <div className="col-lg-3">
                      <CollectionItemCard
                        key={i}
                        secondaryBtn={_isStaked}
                        detail={`Lvl ${cardLevel}`}
                        imagePath={`${IpfsImageUrl}/${a?.data?.img}`}
                        rarity={a?.data?.name}
                        buttonText={"Assign"}
                        onButtonClick={async () => {
                          const response = await _handleActivateNFTs(
                            userProperties,
                            activeUser,
                            contract,
                            a.asset_id,
                            gamer.asset_id
                          );

                          if (response.success) {
                            const { farmer, pet } = farmingPageConstants.types;
                            const cardLevel = a.mutable_data?.Level
                              ? parseInt(a.mutable_data?.Level)
                              : 1;

                            const farms = stakedFarms.filter(
                              (sf) => sf.asset_id !== gamer?.asset_id
                            );

                            const templateData = templates.find(
                              (t) =>
                                t.template_id ===
                                parseInt(a.template.template_id)
                            );

                            const _petId =
                              templateData && templateData?.type === pet
                                ? a.asset_id
                                : 0;

                            const _farmerId =
                              templateData && templateData?.type === farmer
                                ? a.asset_id
                                : 0;

                            const farmData = {
                              asset_id: gamer.asset_id,
                              owner: activeUser,
                              farmer_id: _farmerId,
                              pet_id: _petId,
                              claim_time: 0,
                              result: null,
                              level: cardLevel,
                            };

                            setStakedFarms([...farms, farmData]);
                            // setStakedNFTs([...stakedNFTs, pcData]);
                            setGamerPositive(true);
                            setGamerMessage(`NFT Registration Successful`);
                            return;
                          }

                          setGamerPositive(false);
                          setGamerMessage(response?.responseData.message);
                        }}
                      />
                    </div>
                  );
                })}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={cardModal}
        modalTransparent={false}
        popupMessage={cardModalMessage}
        messagePositive={cardPositive}
        className="farming-page__modal-content"
        title="Staked Farms"
        onModalClose={() => {
          setCardModal(false);
          setCardModalMessage("");
        }}
      >
        {assets
          .filter((a) => {
            const cardDetail = stakedFarms.find(
              (s) => a.asset_id === s.asset_id
            );

            const hasFarmer = cardDetail && cardDetail.farmer_id !== 0;

            return cardDetail && hasFarmer;
          })
          .map((a, i) => {
            const cardDetail = stakedFarms.find(
              (s) => a.asset_id === s.asset_id
            );

            const cardLevel = a.mutable_data?.Level
              ? a.mutable_data?.Level
              : "1";

            const claimed = cardDetail && cardDetail.claim_time !== 0;

            return (
              <div key={i}>
                <div className="farming-page__card-content">
                  <div className="farming-page__action-container">
                    <div>
                      {!claimed ? (
                        <button
                          className="farming-page__btn farming-page__claim-btn"
                          onClick={async () => {
                            const response = await _handleStartFarm(
                              userProperties,
                              activeUser,
                              contract,
                              [parseInt(a?.asset_id)]
                            );

                            if (response.success) {
                              setCardPositive(true);
                              setCardModalMessage("Farm Started Successfully");
                              return;
                            }

                            setCardPositive(false);
                            setCardModalMessage(response?.responseData.message);
                          }}
                        >
                          Start
                        </button>
                      ) : (
                        <button
                          className="farming-page__btn farming-page__claim-btn"
                          onClick={async () => {
                            const response = await _claimRewards(
                              userProperties,
                              activeUser,
                              contract,
                              [parseInt(a?.asset_id)]
                            );

                            if (response.success) {
                              setCardPositive(true);
                              setCardModalMessage("Farm Claimed Successfully");
                              return;
                            }

                            setCardPositive(false);
                            setCardModalMessage(response?.responseData.message);
                          }}
                        >
                          <CountdownTimer timer={cardDetail.claim_time}>
                            CLAIM
                          </CountdownTimer>
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="farming-page__gamer-container">
                    <h6 className="farming-page__text--green farming-page__text--centered">
                      {`Lvl ${cardLevel}`}
                    </h6>
                    <h5 className="farming-page__text--centered  ">
                      {a?.data?.name}
                    </h5>
                    <img
                      className="farming-page__card-image"
                      src={`${IpfsImageUrl}/${a?.data?.img}`}
                      alt="card"
                    />
                    <button
                      className="btn farming-page__action-btn"
                      onClick={async () => {
                        const response = await _handleDeactivateFarm(
                          userProperties,
                          activeUser,
                          contract,
                          a.asset_id
                        );

                        if (response.success) {
                          const filteredStakedGamers = stakedGnomes.filter(
                            (g) => g.asset_id !== a.asset_id
                          );

                          setCardPositive(true);
                          setCardModalMessage(`Gamer Deactivation Successful`);
                          setStakedGnomes(filteredStakedGamers);
                          return;
                        }

                        setCardPositive(false);
                        setCardModalMessage(response?.responseData.message);
                      }}
                    >
                      Deactivate
                    </button>
                  </div>
                  <div className="farming-page__pc-container">
                    {Object.keys(cardDetail)
                      .filter((ki) => {
                        if (ki === "farmer_id" || ki === "pet_id") {
                          const detail = assets.find(
                            (a) => a.asset_id === cardDetail[ki]
                          );

                          return true && detail;
                        }

                        return false;
                      })
                      .map((ki) => {
                        const pcDetail = assets.find(
                          (a) => a.asset_id === cardDetail[ki]
                        );
                        const cardLevel = pcDetail.mutable_data?.Level
                          ? pcDetail.mutable_data?.Level
                          : "1";

                        return (
                          <div className="farming-page__pc-details">
                            <h6 className="farming-page__text--green farming-page__text--centered">
                              {`Lvl ${cardLevel}`}
                            </h6>
                            <h5 className="farming-page__text--centered  ">
                              {pcDetail?.data?.name}
                            </h5>
                            <img
                              className="farming-page__card-image"
                              src={`${IpfsImageUrl}/${pcDetail?.data?.img}`}
                              alt="card"
                            />
                            <button
                              className="farming-page__action-btn btn"
                              onClick={async () => {
                                const response = await _handleDeactivatePC(
                                  userProperties,
                                  activeUser,
                                  contract,
                                  cardDetail[ki]
                                );

                                if (response.success) {
                                  const farms = stakedFarms.filter(
                                    (sg) => sg.asset_id !== cardDetail?.asset_id
                                  );

                                  const nfts = stakedNFTs.filter(
                                    (sg) => sg.asset_id !== cardDetail[ki]
                                  );

                                  const _petId =
                                    ki === "pet_id" ? 0 : cardDetail.pet_id;

                                  const _farmerId =
                                    ki === "farmer_id"
                                      ? 0
                                      : cardDetail.farmer_id;

                                  const farmData = {
                                    asset_id: gamer.asset_id,
                                    owner: activeUser,
                                    farmer_id: _farmerId,
                                    pet_id: _petId,
                                    claim_time: 0,
                                    result: null,
                                    level: cardLevel,
                                  };

                                  setCardPositive(true);
                                  setCardModalMessage(
                                    "Deactivation Successful"
                                  );

                                  setStakedNFTs(nfts);
                                  setStakedFarms([...farms, farmData]);
                                  return;
                                }

                                setCardPositive(false);
                                setCardModalMessage("Deactivation Successful");
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            );
          })}
      </Modal>

      <Modal
        isOpen={exchangeModal}
        modalMessage={exchangeModalMessage}
        messagePositive={exchangePositive}
        className="farming-page__modal-content"
        onModalClose={() => {
          setExchangeModal(false);
          setExchangeBits(false);
          setExchangeModalMessage("");
          setExchangeValue(0);
        }}
      >
        <div className="farming-page__modal-body">
          <div className="header-form farming-page__modal-input">
            <form>
              {exchange !== exchangeType.flpts ? (
                <label className="farming-page__text--white">
                  How many {exchange === exchangeType.seeds && "LANBX"}
                  {exchange === exchangeType.lanbx && "BITS"} will you like to
                  sell?
                </label>
              ) : (
                <label className="farming-page__text--white">
                  How many FLPTS would you like to buy?
                </label>
              )}

              <input
                step={`any`}
                type="text"
                className="txt"
                value={exchangeValue ? exchangeValue : ""}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const isValid = isWholeNumber(inputValue);
                  const isZero = e.target.value === 0;

                  if (!inputValue) setExchangeValue(0);
                  if (isValid || isZero) setExchangeValue(inputValue);
                }}
              />
            </form>
          </div>
        </div>

        {!balances.length ? null : (
          <ProductList
            centered
            title="Your Current Balance"
            className="author-product-meta"
            productList={[...balances, lanbxBalances]}
          ></ProductList>
        )}

        {!config ? null : (
          <div className="farming-page__modal-notes">
            <label className="farming-page__text--white">
              LANBX ➡ SEEDS Exchange Rate: 1 LANBX ={" "}
              {exchangeBits ? config[0]?.swap_rate : `4 SEEDS`} <hr />
              BITS ➡ FLPTS Exchange Rate: 2 BITS = 1 FLPTS <hr />
              The exchange rate is a variable rate and can change over time.
            </label>
          </div>
        )}

        <div className="farming-page__modal-actions">
          {exchange === exchangeType.seeds && (
            <button
              disabled={!exchangeValue}
              className="farming-page__modal-btn btn"
              onClick={async () => {
                const transactionData = [
                  {
                    contractAccount: landboxContract,
                    actionName: "transfer",
                    data: {
                      from: activeUser,
                      to: contract,
                      quantity: `${getToFixedNumber(exchangeValue, 4)} LANBX`,
                      memo: "seeds",
                    },
                  },
                ];

                const response = await transactionSigner(
                  userProperties,
                  transactionData
                );

                if (response.success) {
                  setExchangeModalMessage("Your exchange was successful.");
                  setExchangePositive(true);
                  return;
                }

                setExchangeModalMessage(response?.responseData?.message);
                setExchangePositive(false);
              }}
            >
              Exchange For SEEDS
            </button>
          )}

          {exchange === exchangeType.lanbx && (
            <button
              disabled={!exchangeValue}
              className="farming-page__modal-btn btn"
              onClick={async () => {
                const transactionData = [
                  {
                    contractAccount: contract,
                    actionName: "swaptkn",
                    data: {
                      player: activeUser,
                      quantity: `${getToFixedNumber(exchangeValue, 4)} BITS`,
                    },
                  },
                ];

                const response = await transactionSigner(
                  userProperties,
                  transactionData
                );

                if (response.success) {
                  setExchangeModalMessage("Your exchange was successful.");
                  setExchangePositive(true);
                  return;
                }

                setExchangeModalMessage(response?.responseData?.message);
                setExchangePositive(false);
              }}
            >
              Exchange For LANBX
            </button>
          )}

          {exchange === exchangeType.flpts && (
            <button
              disabled={!exchangeValue}
              className="farming-page__modal-btn btn"
              onClick={async () => {
                const transactionData = [
                  {
                    contractAccount: contract,
                    actionName: "swaptkn",
                    data: {
                      player: activeUser,
                      quantity: `${getToFixedNumber(exchangeValue, 0)} FLPTS`,
                    },
                  },
                ];

                const response = await transactionSigner(
                  userProperties,
                  transactionData
                );

                if (response.success) {
                  setExchangeModalMessage("Your exchange was successful.");
                  setExchangePositive(true);
                  return;
                }

                setExchangeModalMessage(response?.responseData?.message);
                setExchangePositive(false);
              }}
            >
              Exchange For FLPTS
            </button>
          )}

          <div>
            {exchange !== exchangeType.seeds && (
              <button
                className="btn farming-page__secondary-btn"
                onClick={() => {
                  setExchange(exchangeType.seeds);
                  setExchangeModalMessage("");
                  setExchangeValue(0);
                }}
              >
                SWAP TO SEEDS
              </button>
            )}

            {exchange !== exchangeType.lanbx && (
              <button
                className="btn farming-page__secondary-btn"
                onClick={() => {
                  setExchange(exchangeType.lanbx);
                  setExchangeModalMessage("");
                  setExchangeValue(0);
                }}
              >
                SWAP TO LANBX
              </button>
            )}

            {exchange !== exchangeType.flpts && (
              <button
                className="btn farming-page__secondary-btn"
                onClick={() => {
                  setExchange(exchangeType.flpts);
                  setExchangeModalMessage("");
                  setExchangeValue(0);
                }}
              >
                SWAP TO FLPTS
              </button>
            )}
          </div>
        </div>
      </Modal>
      <section className="market-single-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="text-center">
                <button
                  className="farming-page__header-btn"
                  onClick={() => setExchangeModal(true)}
                >
                  <img src="assets/img/sd-ex.png" alt="header-icon" />
                </button>

                <button
                  className="farming-page__header-btn"
                  onClick={() => setCardModal(true)}
                >
                  <img src="assets/img/farm-stake.png" alt="header-icon" />
                </button>

                <button
                  className="farming-page__header-btn"
                  onClick={() => setFarmerModal(true)}
                >
                  <img src="assets/img/farm-upgrades.png" alt="header-icon" />
                </button>

                <a href="/drops">
                  <button className="farming-page__header-btn">
                    <img src="assets/img/2dfarm.png" alt="header-icon" />
                  </button>
                </a>

                <button
                  className="farming-page__header-btn"
                  onClick={() => setResultsModal(true)}
                >
                  <img src="assets/img/farming-results.png" alt="header-icon" />
                </button>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="market-single-top">
                <div className="market-single-title-wrap">
                  <h2 className="title">Currently Farming</h2>
                </div>
                <div className="market-single-action"></div>
              </div>

              <div className="container-fluid dashboard">
                <div className="row" id="draggablePanelList">
                  {/* <div className="col-sm-12 mb-2">
                    <div className="panel alert">
                      <div className="panel-heading">&nbsp;</div>
                      <div className="panel-body alert">
                        <div className="number">
                          {getToFixedNumber(miningPower)}
                        </div>
                      </div>
                      <div className="panel-footer">
                        <div className="title">{} BITS/HR</div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-sm-12 mb-2">
                    <div className="panel">
                      <div className="panel-heading">&nbsp;</div>
                      <div className="panel-body">
                        <div className="number">
                          {removeSuffx(lanbxBalances, /LANBX/gi)}
                        </div>
                      </div>
                      <div className="panel-footer">
                        <div className="title farming-page__background--violet">
                          $LANBX
                        </div>
                      </div>
                    </div>
                  </div>

                  {balances.map((b) => {
                    return (
                      <div className="col-sm-12 mb-2">
                        <div className="panel">
                          <div className="panel-heading">&nbsp;</div>
                          <div className="panel-body">
                            <div className="number">{b}</div>
                          </div>
                          <div className="panel-footer">
                            <div className="title farming-page__background--violet"></div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="activity-table-responsive">
                <h2>FARMING NFTs</h2>
                <div className="mt-3 row">
                  {assets.length &&
                    assets
                      .filter((a) => {
                        const templateData = templates.find(
                          (t) =>
                            t.template_id === parseInt(a.template.template_id)
                        );

                        const cardDetails = stakedFarms.find(
                          (g) => g.asset_id === a.asset_id
                        );

                        const _hasNFTs = cardDetails
                          ? cardDetails?.farmer_id !== 0 &&
                            cardDetails?.pet_id !== 0
                          : false;

                        const _isAFarmer = templateData
                          ? templateData.type ===
                            farmingPageConstants.types.farm
                          : false;

                        return templateData && _isAFarmer && !_hasNFTs;
                      })
                      .map((a, i) => {
                        const { farmingBonus } = farmingPageConstants;
                        const _isStaked = stakedFarms.find(
                          (sg) => sg.asset_id === a.asset_id
                        );

                        const cardLevel = a.mutable_data?.Level
                          ? a.mutable_data?.Level
                          : "1";

                        const levelBonus = farmingBonus.find(
                          (fb) => fb.farmLevel === parseInt(cardLevel)
                        );

                        return (
                          <div className="col-xl-3 col-md-4 col-sm-6">
                            <CollectionItemCard
                              key={i}
                              secondaryBtn={_isStaked}
                              detail={`Lvl ${cardLevel}`}
                              imagePath={`${IpfsImageUrl}/${a?.data?.img}`}
                              rarity={a?.data?.name}
                              floatingHeader={`${levelBonus.bonus}%`}
                              buttonText={
                                _isStaked ? "ASSIGN NFTS" : "ACTIVATE"
                              }
                              secondButtonText="Deactivate"
                              onButtonClick={async () => {
                                if (_isStaked) {
                                  setGamer(a);
                                  return;
                                }

                                const response = await _handleActivateFarm(
                                  userProperties,
                                  activeUser,
                                  contract,
                                  a.asset_id
                                );

                                if (response.success) {
                                  setPopupMessage(
                                    `Farm Registration Successful`
                                  );
                                  const cardLevel = a.mutable_data?.Level
                                    ? parseInt(a.mutable_data?.Level)
                                    : 1;

                                  const farmData = {
                                    asset_id: a.asset_id,
                                    owner: activeUser,
                                    farmer_id: 0,
                                    pet_id: 0,
                                    claim_time: 0,
                                    result: null,
                                    level: cardLevel,
                                  };

                                  setStakedFarms([...stakedFarms, farmData]);
                                  setPopupSuccess(true);
                                  setPopupMessage(
                                    `Farm Registration Successful`
                                  );
                                  return;
                                }

                                setPopupSuccess(false);
                                setPopupMessage(response?.responseData.message);
                              }}
                              onSecondButtonClick={async () => {
                                const response = await _handleDeactivateFarm(
                                  userProperties,
                                  activeUser,
                                  contract,
                                  a.asset_id
                                );

                                if (response.success) {
                                  const farms = stakedFarms.filter(
                                    (sg) => sg.asset_id !== a.asset_id
                                  );

                                  setStakedFarms(farms);
                                  setPopupSuccess(true);
                                  setPopupMessage(
                                    `Farm Deactivation Successful`
                                  );
                                  return;
                                }

                                setPopupSuccess(true);
                                setPopupMessage(response?.responseData.message);
                                return;
                              }}
                            />
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="author-profile-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="market-single-top">
                <div className="market-single-title-wrap">
                  <h2 className="title">Data & Upgrade Center</h2>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 order-2 order-lg-0">
                <AuthorProfileCard
                  imagePath={`assets/img/farm-page.png`}
                  title={``}
                  titleImagePath={``}
                  subTitle=""
                  buttonText="Claim All Farms"
                  onButtonClick={async () => {
                    const assets = stakedFarms
                      .filter((g) => g.owner === activeUser)
                      .map((a) => parseInt(a.asset_id));

                    const claimResponse = await _claimRewards(
                      userProperties,
                      activeUser,
                      contract,
                      assets
                    );

                    if (claimResponse.success) {
                      setPopupSuccess(true);
                      setPopupMessage(`Claimed Farm`);
                      return;
                    }

                    setPopupSuccess(false);
                    setPopupMessage(claimResponse?.responseData.message);
                  }}
                >
                  <h6 className="title">Total Farming Bonus </h6>
                  <ul>
                    {farmingPageConstants.farmingBonus.map((fb) => {
                      return (
                        <li>
                          <a href="/#">
                            <div className="icon">{fb.bonus}%</div>{" "}
                            {fb.farmLevel.toLocaleString()} FARM LEVEL
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                  <hr></hr>
                  <h6 className="title">Currently Farming</h6>
                  <ul>
                    <li>
                      <a href="/#">
                        <div className="icon">
                          {stakedNFTs
                            ? stakedNFTs.filter((p) => p.owner === activeUser)
                                .length
                            : 0}
                        </div>{" "}
                        NFTs
                      </a>
                    </li>
                  </ul>
                </AuthorProfileCard>
              </div>
              <div className="col-xl-9 col-lg-8">
                {!balances.length ? null : (
                  <ProductList
                    className="author-product-meta"
                    productList={[...balances, lanbxBalances]}
                  />
                )}

                <div className="row justify-content-center">
                  {assets.length &&
                    assets
                      .filter((a) => {
                        return _isInsideCardStack(
                          stakedFarms,
                          "asset_id",
                          a.asset_id
                        );
                      })
                      .map((a, i) => {
                        const templateData = templates.find((t) => {
                          return (
                            t.template_id === parseInt(a.template.template_id)
                          );
                        });

                        const _cardLevel = a.mutable_data?.Level
                          ? parseInt(a.mutable_data?.Level)
                          : 1;

                        const _getUpgradeCost = (cardLevel, templateData) => {
                          const defaultUpgradeLevel = 2;
                          const upgradeLevel = cardLevel
                            ? cardLevel + 1
                            : defaultUpgradeLevel;

                          if (templateData && templateData.levelcost.length) {
                            const levelCost = templateData.levelcost.find(
                              (d) => upgradeLevel === parseInt(d?.key)
                            );

                            return levelCost
                              ? getToFixedNumber(levelCost.value)
                              : 0;
                          }

                          return 0;
                        };

                        return (
                          <div className="col-xl-4 col-md-6 col-sm-6">
                            <CollectionItemCard
                              key={i}
                              detail={`Lvl ${_cardLevel}`}
                              imagePath={`${IpfsImageUrl}/${a?.data?.img}`}
                              rarity={a?.data?.name}
                              upgradeCost={`${_getUpgradeCost(
                                _cardLevel,
                                templateData
                              )} FLTPS`}
                              onButtonClick={async () => {
                                const transactionData = [
                                  {
                                    contractAccount: contract,
                                    actionName: "upgradefarms",
                                    data: {
                                      asset_ids: [parseInt(a.asset_id)],
                                      player: activeUser,
                                    },
                                  },
                                ];

                                const upgradeResponse = await transactionSigner(
                                  userProperties,
                                  transactionData
                                );

                                if (upgradeResponse.success) {
                                  setPopupSuccess(true);
                                  setPopupMessage("Upgrade Success");
                                  return;
                                }
                                setPopupSuccess(false);
                                setPopupMessage(
                                  upgradeResponse?.responseData.message
                                );
                              }}
                            />
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </LandBoxContainer>
  );
}

async function _handleStartFarm(userProperties, activeUser, contract, assets) {
  const transactionData = [
    {
      contractAccount: contract,
      actionName: "startfarms",
      data: {
        asset_ids: assets,
        player: activeUser,
      },
    },
  ];

  return await transactionSigner(userProperties, transactionData);
}

async function _handleActivateFarm(
  userProperties,
  activeUser,
  contract,
  assetId
) {
  const transactionData = [
    {
      contractAccount: contract,
      actionName: "regfarms",
      data: {
        asset_ids: [parseInt(assetId)],
        player: activeUser,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);

  return response;
}

async function _handleDeactivateFarm(
  userProperties,
  activeUser,
  contract,
  assetId
) {
  const transactionData = [
    {
      contractAccount: contract,
      actionName: "deregfarms",
      data: {
        asset_ids: [parseInt(assetId)],
        player: activeUser,
      },
    },
  ];

  return await transactionSigner(userProperties, transactionData);
}

async function _handleActivateNFTs(
  userProperties,
  activeUser,
  contract,
  assetId,
  farmId
) {
  const transactionData = [
    {
      contractAccount: contract,
      actionName: "regnfts",
      data: {
        asset_ids: [parseInt(assetId)],
        farm_id: farmId,
        player: activeUser,
      },
    },
  ];

  return await transactionSigner(userProperties, transactionData);
}

async function _handleDeactivatePC(
  userProperties,
  activeUser,
  contract,
  assetId
) {
  const transactionData = [
    {
      contractAccount: contract,
      actionName: "deregnfts",
      data: {
        asset_ids: [parseInt(assetId)],
        player: activeUser,
      },
    },
  ];

  return await transactionSigner(userProperties, transactionData);
}

async function _claimRewards(userProperties, activeUser, contract, assets) {
  const transactionData = [
    {
      contractAccount: contract,
      actionName: "claimfarms",
      data: {
        asset_ids: assets,
        player: activeUser,
      },
    },
  ];

  return await transactionSigner(userProperties, transactionData);
}

const _isCardStaked = (cardStack, assetId, ownerName) => {
  if (cardStack.find((c) => c.asset_id === assetId && c.owner === ownerName))
    return true;

  return false;
};

const _isInsideCardStack = (cardStack, property, value) => {
  if (cardStack.find((c) => c[property] === value)) return true;

  return false;
};
