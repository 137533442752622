import waxUrl from "../constants/waxUrl";
import eosjsName from "eosjs-account-name";

const { rpcEndpoint } = waxUrl;
const CONTRACT = "landfarm.gm";

export async function getConfigurations(
  table = "fconfigs",
  activeUser,
  scope,
  rows = 10000
) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const bounds =
    table === "gnomes" || table === "nfts"
      ? eosjsName.nameToUint64(activeUser)
      : "";
  const key_type = table === "gnomes" || table === "nfts" ? "i64" : "";
  const index_position = table === "gnomes" || table === "nfts" ? 2 : "";

  const urlBody = {
    code: "landfarm.gm",
    scope: scope ? scope : CONTRACT,
    json: true,
    upperBound: bounds,
    lowerBound: bounds,
    key_type: key_type,
    index_position: index_position,
    limit: rows,
    table: table,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}
