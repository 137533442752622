export const getToFixedNumber = (number, decimalPlaces = 2) => {
  const value = parseFloat(number);
  if (!value) return 0;
  return value ? value.toFixed(decimalPlaces) : 0;
};

export function removeSuffx(phrase, removalPhrase) {
  const regEx = new RegExp(removalPhrase);
  return phrase.toString().replace(regEx, "");
}

export function isDecimal(value) {
  const decimalRegEx = /^\d*\.?\d{0,4}$/;
  return value && value.match(decimalRegEx) ? true : false;
}

export function isWholeNumber(value) {
  const wholeregEx = /^\d*$/;
  return value && value.match(wholeregEx) ? true : false;
}

export function sleep(timer) {
  new Promise((resolve) => setTimeout(resolve, timer));
}
