const miningPageConstants = {
  types: {
    gamer: "gamer",
    pc: "pc",
  },
  regenLimit: 15,
  miningBonus: [
    {
      bitValue: 1000,
      bonus: 1,
    },
    {
      bitValue: 2500,
      bonus: 2.5,
    },
    {
      bitValue: 7500,
      bonus: 7.5,
    },
    {
      bitValue: 15000,
      bonus: 15,
    },
    {
      bitValue: 50000,
      bonus: 20,
    },
    {
      bitValue: 100000,
      bonus: 25,
    },
    {
      bitValue: 250000,
      bonus: 30,
    },
    {
      bitValue: 500000,
      bonus: 35,
    },
    {
      bitValue: 1000000,
      bonus: 40,
    },
  ],
};
export default miningPageConstants;
