import React from "react";
import LandBoxContainer from "../LandBoxContainer";
import "@neftyblocks/drops";

import "./index.scss";

export default function DropsPage({ activeUser, showWaxModal, onUserLogout }) {
  return (
    <LandBoxContainer
      activeUser={activeUser}
      showWaxModal={showWaxModal}
      onUserLogout={onUserLogout}
    >
      <div className="drops-page">
        <div className="drops-page__container">
          <neftyblocks-drops
            collection="landboxgames"
            limit="12"
          ></neftyblocks-drops>
        </div>
      </div>
    </LandBoxContainer>
  );
}
