import React from "react";

const SingleMarket = () => {
  return (
    <section className="market-single-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="market-single-img">
              <img src="assets/img/nerd-computer.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="market-single-top">
              <div className="market-single-title-wrap">
                <h2 className="title">Mining</h2>
              </div>
              <div className="market-single-action"></div>
            </div>
            <div className="highest-bid-wrap">
              <div className="row">
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <h5 className="title">Computer Pack</h5>
                  <div className="highest-bid-avatar">
                    <div className="thumb">
                      <img src="assets/img/puter.png" alt="" />
                    </div>
                    <div className="content">
                      <h5 className="title">Computer Pack</h5>
                      <span>
                        <img
                          src="assets/img/token.png"
                          alt="token"
                          style={{ width: "20px" }}
                        />{" "}
                        40 LANBX
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <a href="/#" className="place-bid-btn">
                    Buy a Pack
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="activity-table-responsive">
              <table className="table activity-table">
                <thead>
                  <tr>
                    <th scope="col" className="title">
                      Gamer NFTs
                    </th>
                    <th scope="col">Mint #</th>
                    <th scope="col" className="time">
                      Activate/Deactivate
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" className="author">
                      <img
                        src="assets/img/ROBOT.png"
                        style={{ width: "50px" }}
                        alt=""
                      />{" "}
                      <a href="#">Robot</a>
                    </th>
                    <td>1</td>
                    <td>
                      <a href="/#" className="place-bid-btn">
                        Activate
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="activity-table-responsive">
              <table className="table activity-table">
                <thead>
                  <tr>
                    <th scope="col" className="title">
                      Computer NFTs
                    </th>
                    <th scope="col">Level</th>
                    <th scope="col" className="time">
                      Activate/Deactivate
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" className="author">
                      <img
                        src="assets/img/COMMON.png"
                        style={{ width: "50px" }}
                        alt=""
                      />{" "}
                      <a href="#">Common</a>
                    </th>
                    <td>Lvl 1</td>
                    <td>
                      <a href="/#" className="place-bid-btn">
                        Activate
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="author">
                      <img
                        src="assets/img/RARE.png"
                        style={{ width: "50px" }}
                        alt=""
                      />{" "}
                      <a href="#">Rare</a>
                    </th>
                    <td>Lvl 20</td>
                    <td>
                      <a href="/#" className="place-bid-btn">
                        Activate
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="author">
                      <img
                        src="assets/img/EPIC.png"
                        style={{ width: "50px" }}
                        alt=""
                      />{" "}
                      <a href="#">Epic</a>
                    </th>
                    <td>Lvl 7</td>
                    <td>
                      <a href="/#" className="place-bid-btn">
                        Activate
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="author">
                      <img
                        src="assets/img/LEGENDARY.png"
                        style={{ width: "50px" }}
                        alt=""
                      />{" "}
                      <a href="#">Legendary</a>
                    </th>
                    <td>Lvl 42</td>
                    <td>
                      <a href="/#" className="place-bid-btn">
                        Activate
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="author-profile-area">
        <div className="container">
          <div className="row justify-content-center">
            <div class="market-single-top">
              <div className="market-single-title-wrap">
                <h2 className="title">Data & Upgrade Center</h2>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 order-2 order-lg-0">
              <aside className="author-profile-wrap">
                <div className="author-profile-thumb">
                  <img src="assets/img/puter.png" alt="" />
                </div>
                <div className="author-info">
                  <h5 className="title">
                    Mining Power: 0.00 BITS{" "}
                    <img src="assets/img/icons/star.svg" alt="" />
                  </h5>
                  <span>Per Hour</span>
                </div>

                <div className="author-social">
                  <a href="/#" className="place-bid-btn">
                    Collect Rewards
                  </a>
                  <hr></hr>
                  <h6 className="title">Currently Mining</h6>
                  <ul>
                    <li>
                      <a href="/#">
                        <div className="icon">5</div> Gamers
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <div className="icon">10</div> Computers
                      </a>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="author-product-meta">
                <ul>
                  <li className="active">
                    <a href="#">BITS: 0.00</a>
                  </li>
                  <li className="active">
                    <a href="#">LANBX: 0.00</a>
                  </li>
                  <li className="active">
                    <a href="#">Exchange Rate: 1,000 BITS = 0.0005 LANBX</a>
                  </li>
                </ul>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-4 col-md-6 col-sm-6">
                  <div className="top-collection-item">
                    <div className="collection-item-top">
                      <ul>
                        <li className="avatar">
                          <a href="#" className="thumb">
                            <img src="assets/img/puter.png" alt="" />{" "}
                          </a>
                          Lvl 1{" "}
                          <a href="#" className="name">
                            Common
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="collection-item-thumb">
                      <a href="/">
                        <img src="assets/img/COMMON.png" alt="" />
                      </a>
                    </div>
                    <div className="collection-item-content">
                      <h5 className="title">
                        <a href="#">Upgrade Cost </a>{" "}
                        <span className="price">50,000 BITS</span>
                      </h5>
                    </div>
                    <div className="collection-item-bottom">
                      <ul>
                        <li className="bid">
                          <a href="#" className="btn">
                            Upgrade
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-md-6 col-sm-6">
                  <div className="top-collection-item">
                    <div className="collection-item-top">
                      <ul>
                        <li className="avatar">
                          <a href="#" className="thumb">
                            <img src="assets/img/puter.png" alt="" />{" "}
                          </a>
                          Lvl 5{" "}
                          <a href="#" className="name">
                            Rare
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="collection-item-thumb">
                      <a href="/">
                        <img src="assets/img/RARE.png" alt="" />
                      </a>
                    </div>
                    <div className="collection-item-content">
                      <h5 className="title">
                        <a href="#">Upgrade Cost </a>{" "}
                        <span className="price">250,000 BITS</span>
                      </h5>
                    </div>
                    <div className="collection-item-bottom">
                      <ul>
                        <li className="bid">
                          <a href="#" className="btn">
                            Upgrade
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-md-6 col-sm-6">
                  <div className="top-collection-item">
                    <div className="collection-item-top">
                      <ul>
                        <li className="avatar">
                          <a href="#" className="thumb">
                            <img src="assets/img/puter.png" alt="" />{" "}
                          </a>
                          Lvl 17{" "}
                          <a href="#" className="name">
                            Epic
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="collection-item-thumb">
                      <a href="/">
                        <img src="assets/img/EPIC.png" alt="" />
                      </a>
                    </div>
                    <div className="collection-item-content">
                      <h5 className="title">
                        <a href="#">Upgrade Cost </a>{" "}
                        <span className="price">650,000 BITS</span>
                      </h5>
                    </div>
                    <div className="collection-item-bottom">
                      <ul>
                        <li className="bid">
                          <a href="#" className="btn">
                            Upgrade
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SingleMarket;
