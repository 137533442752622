import React, { Fragment } from "react";
import Countdown from "react-countdown";

export function CountdownTimer({ className = "", timer = 0, children }) {
  const timerDate = new Date(0);
  const countdownTimer = timerDate.setUTCSeconds(timer);

  const Header = ({ hours, minutes, seconds, completed }) => {
    return completed ? (
      <Fragment>{children}</Fragment>
    ) : (
      <span className={className}>
        {hours}:{minutes}:{seconds}
      </span>
    );
  };

  return <Countdown date={countdownTimer} renderer={Header} />;
}
