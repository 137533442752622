import classNames from "classnames";
import "./index.scss";

export function ProductList({
  title = "",
  centered = false,
  className = "",
  productList,
  list = [],
  children,
}) {
  const productListClass = classNames({
    "product-list--centered": centered,
  });
  const prodList = productList.map((li, i) => {
    return (
      <li className="active" key={i}>
        <a href="#">{li}</a>
      </li>
    );
  });

  const additionalList = list.map((li, i) => {
    return (
      <li className="active" key={i}>
        <a href="#">{li}</a>
      </li>
    );
  });

  return (
    <div className={className}>
      {!title ? null : (
        <span className="product-list__text--white">{title}</span>
      )}
      <ul className={productListClass}>
        {prodList} {additionalList}
      </ul>
      {children}
    </div>
  );
}
