import waxUrl from "../constants/waxUrl";
import eosjsName from "eosjs-account-name";

const { contract, rpcEndpoint } = waxUrl;

async function fetchAllRows(table, scope, lowerBound, keyType, indexPosition, rows) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  let allRows = [];
  let nextKey = lowerBound;

  do {
    const urlBody = {
      code: contract,
      scope: scope,
      json: true,
      lower_bound: nextKey,
      upper_bound: "",
      key_type: keyType,
      index_position: indexPosition,
      limit: rows,
      table: table,
    };

    const options = {
      method: "POST",
      body: JSON.stringify(urlBody),
      mode: "cors",
      credentials: "omit",
    };
    const response = await fetch(url, options);
    const json = await response.json();

    if (response.status !== 200) {
      return { success: false, responseData: json };
    }

    allRows = allRows.concat(json.rows);
    nextKey = json.next_key;

  } while (nextKey);

  return { success: true, responseData: { rows: allRows } };
}

export async function getConfigurations(
  table = "bconfigs",
  activeUser,
  scope,
  rows = 1000
) {
  const bounds = (table === "gamers" || table === "pcs") && activeUser
    ? eosjsName.nameToUint64(activeUser)
    : "";
  const keyType = table === "gamers" || table === "pcs" ? "i64" : "";
  const indexPosition = table === "gamers" || table === "pcs" ? 2 : "";

  return await fetchAllRows(table, scope ? scope : contract, bounds, keyType, indexPosition, rows);
}
